import React, { Component, useState, useEffect} from 'react';

import { Auth, API } from 'aws-amplify';

import { generateUniqueID, SEVERITIES,getStreamURL } from '../Util.js';
import { PERIPHERAL_TIMEOUT} from '../Util.js';
import {closestInCol,getDetailsAtPos,getPosition } from '../Util.js';
import {cleanOldTabRecordsFromStorage, TAB_EXPIRE_TIME, TAB_STALE_TIME} from '../Util.js';

import {VIDEOREVIEW_NOREPORT,isPermissionSet} from '../Util-access.js'

import { DebounceQueue} from '../DebouncedQueue.js'

import * as moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';

// Bring in the React libraries for the bootstrap table
import '../Analytics/VideoLister.css';
import './LiveTab.css';
 
import Dexie from 'dexie'

//Get icons and sounds:
import beep1 from "../assets/ding.wav";



import { WebsocketInterface } from '../WebsocketInterface.js';
import { LiveClipInterface } from './LiveClipInterface.js';
import { clipReviewAPI,markViewedAPI } from './LiveTab-APIs.js';

import { LiveAsset } from './LiveAsset.js';


const audio_beep1 = new Audio(beep1); 

//Set up frequency of polling activities:
const POLL_PENDING_CLIPS = 5*60*1000; //5 minutes
// const POLL_PENDING_CLIPS = .5*60*1000; //30 seconds
const POLL_ASSETS = 10*1000; //10 seconds

/*
* @brief A compenent that can list all videos for the current user (group) in a table
*/
class LiveTab extends Component {
  constructor(props) {
    super(props);
    
    //Get Assets and their status
    this.refreshAPICall     = this.refreshAPICall.bind(this);
    this.handleAssetReturns = this.handleAssetReturns.bind(this);
    this.updateAssetData = this.updateAssetData.bind(this);
    this.updateAssetButtons = this.updateAssetButtons.bind(this);

    //Tracking functions:
    this.trackReview  = this.trackReview.bind(this);
    this.markViewed   = this.markViewed.bind(this);

    //Clip receipt    
    this.addClip          = this.addClip.bind(this);
    this.getPendingClips  = this.getPendingClips.bind(this);
    this.clipsLoaded      = this.clipsLoaded.bind(this);

    //Message and caching configuration
    this.configureWebSocket       = this.configureWebSocket.bind(this);
    this.onWSMessage              = this.onWSMessage.bind(this);
    this.configureIndexDB         = this.configureIndexDB.bind(this);
    this.clearPreviousDBInstances = this.clearPreviousDBInstances.bind(this);

    //Notecard handling:
    this.addNotecard = this.addNotecard.bind(this);

    //UI interaction
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setActiveKey   = this.setActiveKey.bind(this);
    this.firstTab       = this.firstTab.bind(this);
    this.windowResized  = this.windowResized.bind(this);
    
    //Render helpers
    this.renderColumn = this.renderColumn.bind(this);

    //Set the state variables
    this.state = {
      activeTabKey:null, //the currently selected asset button name
      tabs:[],  //hold the details for each asset button
      tabCols:[],  //hold the details for the asset buttons in a column sets
      videosByAsset:{}, //hold a list of assets that have videos loaded (used in the key navigation)     
      winSize:{
        width: 1000,
        height: 500,
      },
      assetWarnings: [],
      assetOffline: [],
      pendingInterval:null,
      postLoadInterval:null,
      db: null // db connection to store the clips as they are downloaded
    };
    this._isMounted=false; //Indicate that the onMount, or UnMount has run

  }
  

  windowResized() {  
    // console.log("Doc: ", document.documentElement.clientWidth,document.documentElement.clientHeight,document.documentElement.clientHeight *.75 )
    this.setState({
        winSize : {
            width: document.documentElement.clientWidth *.75,
            height: document.documentElement.clientHeight *.75,
        }
    });
  }

  /* Call the API (polling) to get any unreviewed clips or asset status/display values */
  refreshAPICall() {
    if(!this.props.groupconfig.bLoaded){ //Make sure that the filters and group configurations are loaded before calling
      console.log("Group config not loaded");
      //If not then call again in 5 seconds
      setTimeout( ()=>{this.refreshAPICall();}, 5*1000);
      return;
    }    // console.log("Calling API as: ",this.props)

    //Generate a list of allowed sites takedn from the possible filters
    let siteList = [];
    if(this.props.possibleFilters && this.props.possibleFilters.Sites 
        && this.props.userInfo && this.props.userInfo.allowedSites && this.props.userInfo.allowedSites[0].length > 1){
        //Extract the name of the sites from the site,gps pair
        (this.props.possibleFilters.Sites || []).forEach(row_=>{  siteList.push(row_.site); })
    } 

    //Get the API data to check for Offline or System Warnings:
    //------------------------------------------------------
    //Get a current token to pass to the API call
    Auth.currentSession().then(
      (auth) => {

        //Configure the API call
        let serviceName= "TrifectaAPI";            
        let apiName= "/getAssetCheckins";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            mode: "fetch",
            caller: 'live',
            Sites: siteList.length>0?siteList.join(','):null,
          }
        };
        //Call the API
        const getAssetsPromise = API.post(serviceName,apiName, myInit);
        getAssetsPromise.then(_data=>{this.handleAssetReturns(_data)});
        getAssetsPromise.catch(_error=>{console.log("Error call getAssetCheckins: ",_error);});
    }).catch( (error)=>{ //authorization fails:
    })    
    ;//end Auth check
    


    //Get the API data to check for updates to the state or colors, determine which assets are displayed
    //------------------------------------------------------
    //Get a current token to pass to the API call
    Auth.currentSession().then(
      (auth) => {

        //Configure the API call
        let serviceName= "TrifectaCoreAPI";            
        let apiName= "/getLiveAssets";
        let myInit = {
          body: {
            token: auth.idToken.jwtToken,
            mode: 'fetch',
            clientid: this.props.groupconfig.group,
          }
        };
        //Call the API
        const getAssetsPromise = API.post(serviceName,apiName, myInit);
        getAssetsPromise.then(_data=>{this.handleAssetReturns(_data)});
        getAssetsPromise.catch(_error=>{console.log("Error call getLiveAssets: ",_error);});
    });//end Auth check


    //Schedule the next refresh of API calls
    this.setState({refreshTimeoutID: setTimeout( ()=>{this.refreshAPICall();}, POLL_ASSETS)});


    return;
    

  }//end refreshAPICalls

  /*
  * @brief Takes care of updating the list with new data when we receive it
  */
  handleAssetReturns(_data) {
    // console.log("HandleAssetReturns: ",_data);
    if(!_data){return;}//make sure that something was returned


    //check-in data from the asset list table, process for offline and system warning
    if(_data.checkins){ 
      //Sets of the 
      let systemWarningSet = [];
      let offlineSet = [];
  
      //Loop over the returned values, mark any with warnings:
      try {
        for(const asset_ of _data.checkins){
          try {
            // Check if the cameras are not connected, but the asset is running
            if(asset_.cabincamera.time && asset_.drivercamera.time){
              let timeSinceCabin =  moment(asset_.lastupdate).diff(moment(asset_.cabincamera.time),'seconds');   //get time since checkin on the cabin camera
              let timeSinceDriver =  moment(asset_.lastupdate).diff(moment(asset_.drivercamera.time),'seconds');  //get time since checkin on the driver camera
              if((timeSinceCabin >PERIPHERAL_TIMEOUT)&&(timeSinceDriver >PERIPHERAL_TIMEOUT)){ //are both cameras offline
                try {
                  //Add the asset to the list of system warning to display
                  systemWarningSet.push({asset:asset_.assetid.toUpperCase(),site:asset_.site.toLowerCase(),contact:null})
                } catch (error) {}
              }
            }
            //Check if the asset is offline: Asset has checked in between 2 hours and 10 hours
            if(asset_.sinceupdate > (2*60*60) && asset_.sinceupdate < (10*60*60)){
              try {
                // console.log("Offline asset: ",asset_,data.result)
                //Add the asset to the list of offline warning to display
                offlineSet.push({asset:asset_.assetid.toUpperCase(),site:asset_.site.toLowerCase(),contact:null}) 
              } catch (error) {}
            }
  
          } catch (error) { //catch error processing one asset, don't let that stop other asset data
            // console.log("Error: ",asset_.assetid,error);    
          }
        }  
      } catch (error) {
        console.log("Error: ",error);
      }
  
      this.setState({assetWarnings:systemWarningSet,assetOffline:offlineSet});
    }

    //Process updates to avaiable assets, change colors, position etc
    if(_data.assets){

      // console.log("Asset data: ",data);
      //Set up two sets of buttons, 
      let tabsLocal = this.state.tabs||[]; //all buttons
      let colsLocal = this.state.tabCols||[]; //buttons grouped by column
      //add into sets and an array
      let sets = {}; //sets don't allow duplicates
      let sortedTabs = [];
      //Group into sites:
      for(const asset_ of (_data.assets||[])){
        if(!sets[asset_.siteid]){sets[asset_.siteid]=[]}
        sets[asset_.siteid].push(asset_);
      }

      let siteSort = Object.keys(sets);
      siteSort = siteSort.sort(); //sort the keys so they show in alphabetical order of the sites in the columns


      // console.log("Sets:" ,siteSort);
      //Iterate over each site
      // for(const set_ of Object.keys(sets)){
      for(const set_ of siteSort){
        //Sort based on the name of the asset (ascending order)
        //within the set, keep them grouped by the site name
        let set = [...sets[set_]];
        set.sort((a, b) => {
          if (a.assetid < b.assetid) {  return -1;  }
          if (a.assetid > b.assetid) { return 1;}
          return 0;
        });
        //Push onto the combined list
        sortedTabs.push(...set)
      }

      //Iterate over the list and update to match the values that are used in the display of the columns
      //  console.log("Proccess API return: ",sortedTabs)
      for(const asset_ of sortedTabs){

         let returnedSets = this.updateAssetButtons(tabsLocal,colsLocal,asset_,false);
         //Update the local copy with the changes returned from the method call.
         tabsLocal = returnedSets.tabs;
         colsLocal = returnedSets.tabCols;
        //  console.log("Returned:",returnedSets)
      }
      // console.log("Asset layout: ",tabsLocal, colsLocal);

      //Place it into state to display
      this.setState({tabs:tabsLocal, tabCols:colsLocal});
      
      //Required to support the withLoadingAnimation wrapper
      if(this.props.loadingComplete){this.props.loadingComplete(true);}
    }

    return;
  }//handleAssetReturns


  updateAssetData(_tabsLocal, _asset, _update){
    try {
      
      //match the data against the input asset:
      let foundData = (_tabsLocal||[]).find(elem_=>{return elem_.eventKey.toLowerCase()===_asset.assetid.toLowerCase()})
      if(foundData){ //update the known data
        if(_asset.display){ //if display data is available
          //Configure the colors and column
          let columnNum= parseInt(_asset.display.col,10); 
          foundData.col = columnNum;
          foundData.color = _asset.display.color;
          foundData.activeColor= _asset.display.activeColor;
        }//end display configuration

        if(_asset.status){foundData.status = _asset.status;}
        //Track requested commands:
        if(_asset.lastcommandtime){foundData.lastcommand= _asset.lastcommandtime;}
        if(_asset.timezone){foundData.timezone= _asset.timezone;}

        if(_asset.status && _asset.status === 'disabled'){
          console.log("Handle delete: ",_asset.assetid);
        }
      }
      _tabsLocal =_tabsLocal.filter( button=>{return button.status !== 'disabled'})

      if(_update){
        // console.log("Update asset data: ",_asset);
        this.setState({tabs:_tabsLocal});
      }else{
        return _tabsLocal;
      }
    } catch (error) {
      console.log("Failed to update the tabs in handleAssetRefresh");
    }
  }
  /* Add an asset to the button sets to display on the tab*/
  updateAssetButtons(_set, _columnSet, _asset,_setState){
    if(!_asset){
      return ({tabs:_set, tabCols:_columnSet});
    }

    let tabExists = false;
    let hasChanged = false;

    //  let tabsLocal = [..._set];
    //  let colsLocal = _columnSet?JSON.parse(JSON.stringify(_columnSet)):[[]]; //JSON.parse(JSON.stringify()) --deep copy an object
    let tabsLocal = _set;
    let colsLocal = [[]]; //this can be recreated, but we need to keep the same references from tabsLocal

    //  console.log("colsLocal: ",colsLocal);
    //Search over the current buttons, and check if the button needs to be added
    (tabsLocal||[]).forEach(button_=>{
      // console.log("tab: ",tab_);
      if(button_.eventKey.toLowerCase()===_asset.assetid.toLowerCase()){
        // console.log("Found vehicle");
        tabExists = true;
      }
    })


    let columnNum = 0;
    //  console.log("Update button: ",_asset.assetid,this.props.possibleFilters.Sites)
    //Add a new tab, when a new vehicle sends an infraction
    if(!tabExists && (_asset.status &&_asset.status != 'disabled')){
      // console.log("Not in tab?", _clip.assetid)
      let assetButton = {
        eventKey:_asset.assetid,
        title:_asset.assetid,
        set: null, last:null,
        clientid:_asset.clientid,
        siteid:_asset.siteid.toLowerCase(),
        col: 0,
        color: "rgba(119, 221, 119,0.25)",
        activeColor: "rgba(119, 221, 119,0.75)",
        // childRef: React.createRef(), //direct reference to the HTML element, may be deprecated
        status: _asset.status||'enabled',
        lastcommand: _asset.lastcommandtime,
        timezone: _asset.timezone
      }
      try {
        assetButton.siteDetails= (this.props.possibleFilters.Sites||[]).filter(elem_ => {
          // console.log("Compare",elem_.site.toLowerCase(),_asset.siteid.toLowerCase());
          return elem_.site.toLowerCase()===_asset.siteid.toLowerCase()}
        );
      } catch (error) {
        
      }

      //Update the display if there is data:
      if(_asset.display){
                
        columnNum= parseInt(_asset.display.col,10);
        assetButton.col =columnNum;
        assetButton.color = _asset.display.color;
        assetButton.activeColor= _asset.display.activeColor;
      }
      //add to the combined set:
      try {
        hasChanged = true;
        tabsLocal.push(assetButton);
      } catch (error) {
        console.log("Bad asset?: ",_asset,error);
      }
    }   
    else{
        tabsLocal = this.updateAssetData(tabsLocal,_asset,false);
        // //Update the reference in the tabs:
        // // console.log("tabs local: ",tabsLocal);
        // try {
        //   (tabsLocal||[]).forEach(button_=>{
        //     if(button_.eventKey.toLowerCase()===_asset.assetid.toLowerCase()){
        //       // Update the display if there is data:
        //       if(_asset.display){
        //         columnNum= parseInt(_asset.display.col,10);
        //         button_.col =columnNum;
        //         button_.color = _asset.display.color;
        //         button_.activeColor= _asset.display.activeColor;
        //       }

        //       button_.status = _asset.status;
        //       button_.lastcommand= _asset.lastcommandtime;
        //       button_.timezone= _asset.timezone;
        //       if(_asset.status && _asset.status === 'disabled'){
        //         console.log("Handle delete: ",_asset.assetid);
        //       }
        //     }
        //   });  

        //   tabsLocal =tabsLocal.filter( button=>{return button.status !== 'disabled'})
          // console.log("Tabs local:" ,tabsLocal);

        // } catch (error) {
        //   console.log("Failed to update the tabs in handleAssetRefresh");
        // }

    } //end else condition  

    //Update in the colsLocal
    (tabsLocal||[]).forEach(button_=>{
      try {
        //push the asset tag to the referenced column
        if(!colsLocal[button_.col]){colsLocal[button_.col]= []};//Allocate the column if not found:
        colsLocal[button_.col].push(button_)
      }catch (error) {
        console.log("Failed to add to column set: ",_asset, columnNum, error);
      }
    });

    //Create a common object to return
    let returnObj = {tabs:tabsLocal, tabCols:colsLocal};

    if(_setState && hasChanged){
      this.setState(returnObj);
    }else{
      return returnObj;
    }
  } //end updateAssetButtons

  /* 
  * Process the new clip data recieved, if unique, add it to the correct button
  */
  addClip(_clip, _update){
    //Only allow the EDGE3 clips to load on the dev group test user
    if(this.props.groupconfig && this.props.groupconfig.group.toLowerCase()!=='devgroup' && _clip.assetid.includes('EDGE3')){
       return;
    }
    LiveClipInterface.addClip({clip:_clip, assetid: _clip.assetid});
    return;
  }//end addclip

  /* 
  * Add a notecard to the local list
  */
  addNotecard(_card){
    // console.log("Add card: ",_card);
    LiveClipInterface.addClip({notecard: _card, assetid: _card.vehicleid});
    return;
  }//end addNotecard

  /**
   * Record that there are clips in the asset button, this is needed to enable keyboard based navigation
   * @param {*} _asset 
   */
  clipsLoaded(_asset){
    //Make sure the tab exists based on the vehicle type:
    const videolist = this.state.videosByAsset;
    // console.log("vidoelist: ",videolist);
    //Check if this exist, if not init with empty set:
    if(!videolist[_asset]){
      videolist[_asset]={loaded:true}
      this.setState({videosByAsset:videolist});
    }
    //Trigger the first tab check to default the loading active button to the first loaded clip
    this.firstTab(_asset);
  }//end clipsLoaded

  //Handle the key presses to navigate the asset list
  handleKeyPress(_event){
    // console.log("Enter key press: ",_event,this.state.activeTabKey, this.state.tabCols);
    // console.log(`Key: ${_event.key} with keycode ${_event.keyCode} has been pressed`);
    try{
      //Consume the keystrokes: 
      switch(_event.key){
        case 'ArrowLeft':   
        case 'ArrowRight':  
        case 'ArrowUp':     
        case 'ArrowDown':   {
          _event.stopPropagation();
          _event.preventDefault();
        } break;
        default: {
          // console.log("Non arrow key press");
          return;

        }
      }
      //Determine the current position of the active key in the button array
      let currentPosition = getPosition(this.state.tabCols,this.state.activeTabKey);
      if(!currentPosition){ console.log("No position found for ", this.state.activeTabKey); return;}
      // console.log("Current position: ",currentPosition);
      let moveBias = {row:-1, col:-1};

      //Save the desired direction of the movement from the key press
      switch(_event.key){
        case 'ArrowLeft':   {moveBias = {row:0, col:-1} } break;
        case 'ArrowRight':  {moveBias = {row:0, col:1}  } break;
        case 'ArrowUp':     {moveBias = {row:-1, col:0} } break;
        case 'ArrowDown':   {moveBias = {row:1, col:0}  } break;
        default:            {moveBias = {row:0, col:0}  } break
      }

      let bNewPositionFound = false;
      let newPosition = {};
      try {
        //Set the maximum traversal for the scan to terminate
        let maxScans = this.state.tabCols.length; //initialize to the width of the array
        (this.state.tabCols||[]).forEach( (col_, colIdx_) =>{
          if(!col_||col_===undefined){return;}
          maxScans = Math.max(maxScans,col_.length); //check against the height of the array
        });
        // console.log("Max scan size: ",maxScans);

        //Update a test location:
        newPosition = { row: currentPosition.row + moveBias.row, col: currentPosition.col + moveBias.col}
        // console.log("newPostition:" ,newPosition);

        let scanCount=0;
        //Scan the available assets to fullfill the desired movement
        while(!bNewPositionFound && scanCount < maxScans){ //add the max scans so the while loop is limited by the number of rows in the col
          scanCount++; //limit the loop 
          //Test the new location for boundary conditions and clips to view:
          let positionDetails = getDetailsAtPos(this.state.tabCols[newPosition.col], newPosition, this.state.videosByAsset);
          if(positionDetails){ //Found a valid position
            bNewPositionFound = true;
          }
          else{
            
            //Based on the bias direction update position and try again
            if(moveBias.col === 0){//up/down case
              newPosition.row += moveBias.row;
            }else{ //not a vertical action address the left/right case
              //Find closest match in the current column:
              let closestPosition = closestInCol(this.state.tabCols,newPosition,this.state.videosByAsset, maxScans);
              // console.log("closest position: ",closestPosition);
              //is the current column empty?
              if(!closestPosition){ //null means there isn't a valid button in the column
                // console.log("No options in column: ",newPosition.col,newPosition.col + moveBias.col)
                newPosition.col += moveBias.col;
                // if(newPosition.col >= this.state.tabCols.length){newPosition.col = 1;} //enable wrap?
                // if(newPosition.col <0 ){newPosition.col = this.state.tabCols.length-1;} //enable wrap?
                if(newPosition.col >= this.state.tabCols.length){return;} //enable wrap?
                if(newPosition.col <0 ){return;} //enable wrap?
              }else{
                // console.log("Found column: ",newPosition);
                newPosition = closestPosition;
                bNewPositionFound = true;
              }
            }//end left/right action
          }
        }//end while loop
      } catch (error) {
        console.log("Failed to move: ", error);
        return;
      }

      try {
        if(bNewPositionFound){ //did we find a new position to move to
          this.setActiveKey(newPosition.button.title); //set the active button
        }  
      } catch (error) {
        console.log("Failed to navigate button:",bNewPositionFound,newPosition);
      }
      return;
    }catch(error){
      console.log("Error on key: ",error);
    }
     
  }
  /*
  * Periodically retrieve any unreviewed clips that weren't delieved by the websocket
  */
  getPendingClips(_options){
    // console.log("Call get clips", new Date());
    // TrifectaCoreAPI
    Auth.currentSession().then(
      (auth) => {
        
        let apiName = "TrifectaCoreAPI";
        let path = "/getExistingAlertClips";
        let myInit = {
            body: {
              token: auth.idToken.jwtToken,
              mode: "fetch",
              sessionnumber: window.sessionStorage.getItem('SessionNumber'),
              username: this.props.username,
              tabname: 'LiveTab'
            }
        };
        myInit.body = Object.assign(myInit.body,_options);
        // console.log("Options: ",_options,myInit.body);
        API.post(apiName, path, myInit)
        .then(data=>{
          
          if(!data.result){return;}
          //go through the data, add to the tabs and video list
          data.result.map(row => {
            this.addClip(row,true);
          })
          try {
            if(data.notecards){
              // console.log("Returned notecards: ",data);
              data.notecards.map(row => {
                this.addNotecard(row,true);
              })
            }  
          } catch (error) {
          }
          
          // this.setState({videosByAsset: this.state.videosByAsset});
        })

        .catch(error=>{console.log("Fail on get clips: ",window.sessionStorage.getItem('SessionNumber'),error)
            setTimeout(this.getPendingClips,500);
          });
      });
  }

  /* 
  * Set up a new IndexDB for this instance of Live Tab
  * The DB will be tagged with the current session number to prevent any collisions with other tabs
  */
  configureIndexDB(){
    let sessionNumber = window.sessionStorage.getItem('SessionNumber');

    //Create a database that is specific to this tab's session number
    let db = new Dexie('LiveClips-'+sessionNumber);
    db.on("blocked", function() {
      console.log ("Database upgrading was blocked by another window. Please close down any other tabs or windows that has this page open");
    });
    db.on("ready", function() {
      // console.log ("ready Please close down any other tabs or windows that have this page open");
    });
    db.on("versionchange", function() {
      console.log ("Version change Please close down any other tabs or windows that has this page open");
    });

    this.setState({db: db}); //Add the reference to the local state to be accessed by the addclip functions

    //define the list of tabled to add to the database
    let tables = {};
    tables['loads'] = "infractionid"
    //Create the connection to the Database:
    db.version(1).stores(tables) //set the tables into the database
    // console.log("Call open on DB: ",db)
    db.open().then( (db)=> {
      // console.log("Opened? ",db);
    }).catch (function (err) {     // Error occurred
        console.log("Failed to open db: ",err);
    });

  }// end configureIndexDB

  /* 
  * Identify old tabs that have been closed for more than 10 minutes
  * If the database instance still exists for the tab then delete it.
  */
  clearPreviousDBInstances(){
    
    try {
      //Check for stale and expired tabs in the Storage
      let tabReturn = cleanOldTabRecordsFromStorage(window.localStorage,TAB_STALE_TIME,TAB_EXPIRE_TIME);
      // console.log("Process stale tabs: ",tabReturn.staleTabs);
      for (const storageKey_ of tabReturn.staleTabs){ //look at the tabs that haven't been active for over 10 minutes
        //Remove the DB store from the tabs:
        try {
          //extract the session number from the tab record:
          let sessionNumber = storageKey_.split('tab-record-')[1];
          //Connect to the Database:
          let db = new Dexie('LiveClips-'+sessionNumber);
          //Purge the DB:
          if(db){
            db.delete().then(() => {
              // console.log(`Database successfully deleted: LiveClips-${sessionNumber}`, );
            }).catch((err) => {
                console.error("Could not delete database");
            }).finally(() => {});
          }
        } catch (error) {}
      }
    } catch (error) {
      
    }

    //Get a list of all remaining databases, if any are still left clear them here
    try {
      const promiselist = Dexie.getDatabaseNames();
      promiselist.then(data=>{
         // console.log("list of database: ",data);
         for( const dbname_ of data){
          if(dbname_.includes("LiveClips")){
            let sessionNumber = dbname_.split('LiveClips-')[1];
            //check if this is shown in the localstorage
            let tabRecord = window.localStorage.getItem('tab-record-'+sessionNumber);
            if(!tabRecord){
              // console.log("Record not found, need to delete:" ,sessionNumber);
              let db = new Dexie('LiveClips-'+sessionNumber);
              //Purge the DB:
              if(db){
                db.delete().then(() => {
                  // console.log(`Database successfully deleted: LiveClips-${sessionNumber}`, );
                }).catch((err) => {
                    console.error("Could not delete database");
                }).finally(() => {});
              }   
            }
          }
         }
      })
      promiselist.catch(err=>{console.log("No list of database: ",err);})
    } catch (error) {
      
    }
  }// end clearPreviousDBInstances

  /**
   * Receive a message from the websocket
   */
  onWSMessage(receivedMessage){
    //  console.log("Received a message on LT: ",receivedMessage);
    if(receivedMessage.event){ 
      //Did we get a new event? The broadcast types have new clip notifications
      if(receivedMessage.event.includes("broadcast")){
        // console.log("New event received");
        this.props.messageReceived(); //Trigger the callback to play the notification on the NavBar
      
        //play the alert sound
        var playPromise = audio_beep1.play(); 
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
          })
          .catch(error => {
            // Auto-play was prevented
            console.log("Error on playback? ",error);
          });
        }
        //Add the clips to the button
        this.addClip(receivedMessage.message,true);
      }
    
    } //end on message
  }

  /* 
  *Create a new websocket to subscribe to the notifications from the DMS alert clips
  */
  configureWebSocket(){

    // the permissions for the user, if permissions aren't set to live = 'write' then don't allow for notifications to be recieved
    let allowWebSocketAccess = false;
    // (this.props.groupconfig && this.props.groupconfig.permissions && this.props.groupconfig.permissions.live&&
    //   this.props.groupconfig.permissions.live==='write')?true:false;
    if(isPermissionSet(this.props.groupconfig,"live",'write')){allowWebSocketAccess=true;} //backwards compatible
    if(isPermissionSet(this.props.groupconfig,"live",{socket:true})){allowWebSocketAccess=true;} //additional detailed permission set     
    
    // console.log("Set websocket access: ",allowWebSocketAccess);
    
    if(allowWebSocketAccess === false){
      console.log("No access allowed for websocket");
      return;
    }

    WebsocketInterface.register({name:'LiveTab',onMessage:this.onWSMessage, type: 'broadcast'});
  }//end webSocketConfigure

  

  /*Action on first load */
  componentDidMount() {

    // console.log("LiveTab mount: ",this.props);
    this._isMounted = true;

    //Configure the debounce queue:
    DebounceQueue.addQueue({queue_key:"livetab_review",depth:5, type:'object'});
    DebounceQueue.linkAPI("livetab_review",clipReviewAPI);

    DebounceQueue.addQueue({queue_key:"livetab_loaded",depth:4, type:'list'});
    DebounceQueue.linkAPI("livetab_loaded",markViewedAPI);
    
    
    //Set up the IndexDB to prevent duplicate clips
    this.configureIndexDB();
    //Clean up orphaned DB instances from past tabs
    this.clearPreviousDBInstances();

    //Add listeners, this need to be released when the tab is destroyed
    window.addEventListener('keydown',this.handleKeyPress);
    window.addEventListener("optimizedResize", this.windowResized);
    this.windowResized();

    //Get the initial set of API values
    this.refreshAPICall();
    //Query any clips that haven't been reviewed
    this.getPendingClips({limit:25});


    //Set a timeer to continuously trigger, this will make the notification buttons re-eval every 30s
    this.setState({pendingInterval:setInterval(() => {this.getPendingClips();},POLL_PENDING_CLIPS) //run every X ms
                    , postLoadInterval: setTimeout(() => {this.getPendingClips();},60*1000) //run a quick reload 1 minutes after the page load
    });
    
    //Set up the websocket if the user has permissions
    this.configureWebSocket();
  }
  
  /* @brief Run once when the class is leaving
  */
  componentWillUnmount(){
    this._isMounted = false;
    window.removeEventListener('keydown',this.handleKeyPress)
    window.removeEventListener("optimizedResize", this.windowResized);
    // this.ws.close(); //close the connection to the websocket API

    //Flush out pending DebounceQueue objects
    DebounceQueue.emptyQueue('livetab_review');
    DebounceQueue.emptyQueue("livetab_loaded");

    WebsocketInterface.release({name:'LiveTab'});
    

    if(this.state.pendingInterval){clearInterval(this.state.pendingInterval);}
    if(this.state.postLoadInterval){clearInterval(this.state.postLoadInterval);}
    
    if(this.state.refreshTimeoutID){clearTimeout(this.state.refreshTimeoutID);}

    
    this.setState({videos:[]});
    try{
      //Try to force remove all child objects of the video-lister:
      var es = document.getElementsByClassName("video-lister");
      [...es].forEach(e=>{
        var child = e.lastElementChild;  
        while (child) { 
            e.removeChild(child); 
            child = e.lastElementChild; 
        } 
      });
      
    }catch(e){
      console.log("Error on VL release: ",e);
    }

    //Clear the DB table:
     if(this.state.db){
      this.state.db.delete().then(() => {
        // console.log("Database successfully deleted");
      }).catch((err) => {
          console.error("Could not delete database");
      }).finally(() => {
          // Do what should be done next...
      });
    }


  }//end componentWillUnmount


  /** Report that the clips has loaded to the audit_clipreview SQL table */
  trackReview(_clip){
    this.clipsLoaded(_clip.assetid)
    if(this.props.username==='beirut_user' || VIDEOREVIEW_NOREPORT===true){
      // console.log("Don't track on beirut user");
      return ;
    }
    //Get the trucks to display:
    let apiName = "TrifectaCoreAPI";
    let path = "/trackClipReview";
    let myInit = {
        body: {
            mode: 'report',
            infractionid: _clip.infractionid,
            reviewerid: this.props.username,
            timestamp: moment().utc().format("YYYY-MM-DD HH:mm:ss:SSS"),
            clientid: this.props.groupconfig.group,
        }
    };
    API.post(apiName, path, myInit).then(data=>{
        // console.log("Tracking return: ",data);
      
    });
  }//end trackReview

  /**Report that the clips has been reviewed to the dms_alertclips SQL table */
  markViewed = (_details) => {
    if(this.props.username==='beirut_user' || VIDEOREVIEW_NOREPORT===true){
      return;
    }
    try {
      DebounceQueue.updateObject("livetab_loaded",_details.meta.infractionid);
    } catch (error) {
      console.log("MarkView error: ",error);
    }
  }//end ejectclick

  /**Set the active asset button when a clip is loaded, allows for the first loaded clip to select the button*/
  firstTab(_asset){
    if(!this.state.activeTabKey){
      this.setActiveKey(_asset);
    }
  }

  /**
   * Update the asset that is selected, called when the button is clicked
   * @param {*} _key: The title of the button
   */
  setActiveKey(_key){
    try {
      // console.log("Memory: ",window.performance.memory)   
    } catch (error) {
      console.log("Memory fail: ",error);
    }
    this.setState({activeTabKey:_key});//clear the currently playing id since we are changing
  }


  //Handle rendering each column of buttons
  renderColumn(col_,colIndex){
    let returnObj = {html:null, iTotalClipCount:0}
    let iTotalClipCount=0;
    let colkey = "col_"+colIndex;
    // console.log("RenderCol:",col_,colIndex, this.state.videosByAsset);
    if(!col_ || col_===undefined ){ //render an empty column as a space holder
      returnObj.html= <div className="asset-col" key={colkey} />
    }else{ //Check if there are buttons allocated to the column
      returnObj.html=
        <div className="asset-col" key={colkey} >
          {col_.map((tab,assetIndex) =>{         
            return  <LiveAsset key={'asset-live'+assetIndex}
                      config={tab}
                      db = {this.state.db}
                      idx = {assetIndex}
                      onClick = {(_data)=>{
                        if(_data.assetid){this.setActiveKey(_data.assetid);}
                        if(_data.longpress){
                          // console.log("Long pressed: ",_data.longpress)
                          this.updateAssetData(this.state.tabs,{assetid: _data.longpress, lastcommandtime:moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')},true)
                        }
                      }}
                      user = {{username:this.props.username, groupconfig: this.props.groupconfig}}
                      userInfo = {this.props.userInfo}
                      filter = {this.props.filter}
                      activeButton = {this.state.activeTabKey}                      
                      onReview = {this.markViewed}
                      onClipLoad = {this.trackReview}                      
                      warningSet ={this.state.assetWarnings}
                      offlineSet ={this.state.assetOffline}
                      onCardChange = {this.props.cardChange}
                    />
            })  
          }
        </div>    
      returnObj.iTotalClipCount = iTotalClipCount;
    }
    return returnObj;
  } //end renderColumn


  /*
  * @brief Called when the framework determines that the displayed elements on screen need to be updated. 
  */
  render() {
    //---------------------------------
    // Create the tabs to render
    //---------------------------------
    const tabCols = this.state.tabCols;
    let iTotalClipCount = 0;
    //Split the set of asset buttons into three columns
    let buttonColums=[];    
    //The first two columns should split the 24 PVM assets
    for(let idx=1; idx<tabCols.length; idx++){ //ignore the 0 column
      buttonColums[idx] =tabCols[idx];  
    }

    if(this.props.hideRender){return <div>Fetching asset list</div>;}

    //Define the assetGroup div to display the buttons
    let assetGroup = <div className="assetid-block" style={{height:this.state.winSize.height}} >
        {buttonColums.map((col_,colIndex) =>{            
            let returnedCol = this.renderColumn(col_,colIndex); //call a helper function to render the columns, easier to read then a crowded render()
            if(returnedCol){
              iTotalClipCount += returnedCol.iTotalClipCount;
              return returnedCol.html;
            }
            return null;
          }) //end column map
        }
      </div>

    return (
      
      <div className="live-review">        
        <div className='page-title'></div>  

        <div className="live-reviewer" style={{}}>
          {assetGroup}  
        </div>

        <div className="live-reviewer-bottom">
          <div className="controlbuttons">
          </div>
        </div>

        
      </div>
    );
  }
}

export { LiveTab };
