import React from 'react';
import { Auth, API } from 'aws-amplify';



import * as moment from 'moment';
// import {EDGE3_PRIMARY_COLORS,EDGE3_SECONDARY_COLORS,EDGE3_TERTIARY_COLORS,commaizeNumber} from '../Util.js'
import BootstrapTable from 'react-bootstrap-table-next';
import { rowStyle } from '../Util-tables';
import { groupBy,countOf, countOf_unique, sumOf,findArrayMedian } from '../Util-object';
import { Spinner } from '../ApiCaller.js';
import { TimeCachedQuery} from '../HelperClasses/TimeOptimizedQuery.js'


import './Chart_AverageReviewTime.css'

/**
 * @brief Get the median time spent reviewing a clip and display it in the table format
 */ 
let startFetchTime = null;
const AverageReviewTime = ({reviewData, ...props}) => {
    
    //Define the state variables
    const [tableData,     setTableData] = React.useState([]); //Create an object to store the data that is shown on the table
    const [tableCols,     setTableCols] = React.useState([]); //Create an object to store the layout/format of the table
    
    /**
     * Effect that is called one time when the component is first loaded
     */
    React.useEffect(() => {
        //Configure a query to use in this context, set the time split for the new vs old as 1 day. The data in the SQL
        //Tables will not be updated after the current date since we are logging the time spent reviewing in real-time
        TimeCachedQuery.addQuery("AverageReviewTime",{timeAmount:1,timeUnit:'day',queryFN:fetchData,responseFN:fetchResponse});

        // console.log("Loaded AverageReviewTime with props: ",reviewData,props);
        //If the reviewData was not provided then we need to fetch it
        if(!reviewData){
            startFetchTime = new Date();
            //Execute a fetch using the TimeCachedQuery class. This will auto split the query based on date ranges to attempt to improve
            //the query tiems
            fetchDataSingle();
            // TimeCachedQuery.query("AverageReviewTime",null,{
            //     mode: 'clip', //Specify a switch mode to indicate that this is to update the review trackers for the clips
            //     date:{
            //         start:props.startDate.format('YYYY-MM-DD'),
            //         end:null
            //     } 
            // });
        }

        //Configure the table columns:
        let tableColumns = [
            {dataField: 'rank', text: 'Rank',editable:false, sort:false,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'username', text: 'Staff Member',editable:false, sort:true,headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},
            {dataField: 'totalclips', text: 'Total Clips Reviewed',editable:false, sort:false, headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},            
            {dataField: 'logged_hours', text: 'Logged Hours',editable:false, sort:false, headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},            
            {dataField: 'mediantime', text: 'Median time per clip',editable:false, sort:false, headerStyle: () => {return { width: "35%",whiteSpace:"center",textAlign:"center"};}},            
        ];
        //set the classnames for each column:
        tableColumns.map(col => {col.classes = 'soc-' + col.dataField; return col;});
        //Save the data in the table format to be displayed
        setTableCols(tableColumns);
        
    },[]);

    /**
     * Process the review stats for the reviewers. Sort and rank the users and add missing values 
     * needed for the table display
     * @param {*} _reviews: combined reviews stats for all the users
     * @returns 
     */
    const processReviewTimes= (_reviews)=>{
        let userReviewData = {};

        if(!_reviews){return null;}
        //Rank the drivers:
        let sortedReview = [];
        //Sort based on the median skip length 
        sortedReview = (Object.values(_reviews)||[]).sort((a,b) => a.medianSkip < b.medianSkip?-1:0);

        //Add a ranking to the reviewers based on the median skip length (less is worse)
        for(let rank =0; rank<sortedReview.length; rank++){            
            try {
                let userData = _reviews[sortedReview[rank].username];
                userData.rank = rank+1;    //add one to the rank to avoid the 0th rank
                userData.mediantime = userData.medianSkip/1000;   //format as seconds from milliseconds
                userData.mediantime = userData.mediantime.toFixed(2); //format to only 2 decimal places                
                userData.logged_hours = userData.logged_hours.toFixed(2); //format to only 2 decimal places                
            } catch (error) {
            }
        }
        //Update sorted order for the display table:
        sortedReview= (sortedReview||[]).sort((a,b) => a.rank < b.rank?-1:0);
        return sortedReview;
    }

    /**
     * Callback function to link to the TimeOptimizedQuery class. This allows the instance to know which api to trigger
     * @param {*} _options 
     */
    const fetchDataSingle= (_options)=>{
        //Configure the API call
        Auth.currentSession().then(
                (auth) => {
            //Configure the API call
            let serviceName= "TrifectaCoreAPI";//Set the servicename - this is the collection of lambdas
            let apiName= "/getClipReview"; //Set the specific lambda function to invoke
            //Configure the data to send to the Lambda
            let myInit = {};
            let body = {
                token: auth.idToken.jwtToken
                ,mode: 'clip' //Specify a switch mode to indicate that this is to update the review trackers for the clips
                ,time:{
                    start:'2024-04-16 03:00:00' //start on am shift yesterday
                    ,end:'2024-04-17 03:30:00' //end after on shift
                    // start:'2024-03-01 04:00:00' //start on am shift yesterday
                    // ,end:'2024-04-01 04:30:00' //end after on shift
                    // ,end:null
                } 
            };
            
            myInit.body = Object.assign(body,_options); //append the options to the query parameters
            console.log("Query with body: ",myInit);
            
            //Call the API
            const apiResponse = API.post(serviceName,apiName, myInit);
                apiResponse.then(_response=>{ //Response recieved from the API
                    console.log("API returned:" ,_response);

                    let combinedReturn = {}
                    // for(const data_ of _response.reviews){
                    for(const [reviewer_,stats_] of Object.entries(_response.reviews)){
                        combinedReturn[reviewer_] = combinedReturn[reviewer_]||{totalSkips:0, medianSkip:0, totalclips:0, logged_hours:0};
                        combinedReturn[reviewer_].medianSkip = stats_.maxSkipTime.median;                    
                        //Track the total number of clips that were reviewed
                        combinedReturn[reviewer_].totalSkips = stats_.maxSkipTime.count;                    
                        combinedReturn[reviewer_].totalclips = stats_.totalclips;     
                        //Sum the total amount of time the user was logged in
                        combinedReturn[reviewer_].logged_hours = stats_.logged_hours;     
                        //Record/update the username in the object
                        combinedReturn[reviewer_].username = reviewer_;
                    }
                    // }

                    let userReviewData = processReviewTimes(combinedReturn || {});
                    console.log("API filtered:" ,Object.values(userReviewData));
                    setTableData(userReviewData);
                });
                //Log error messages: to the console
                apiResponse.catch(_error=>{console.log("Error getClipReview return: ",_error);});
            
        }).catch( (error)=>{console.log("Failed to authenticate session",error)}) //authorization failed:
    }

    /**
     * Callback function to link to the TimeOptimizedQuery class. This allows the instance to know which api to trigger
     * @param {*} _options 
     */
    const fetchData= (_options)=>{
        //Configure the API call
        let serviceName= "TrifectaCoreAPI";//Set the servicename - this is the collection of lambdas
        let apiName= "/getClipReview"; //Set the specific lambda function to invoke
        //Execute the API request and return the promise
        const apiResponse = API.post(serviceName,apiName, _options);
        return apiResponse;
    }
    /**
     * The response from the TimeOptimizedQuery class is assumed to contain multiple parts. This callback is triggered
     * once each part of the query have completed. The responsibility of the callback is to combine the parts of the data into a single 
     * complete query response
     * @param {*} _response 
     */
    const fetchResponse = (_response)=>{
        // console.log("Fetched returned:" ,_response, new Date() - startFetchTime);
        try {
            //Combine with weighted average of medians
            let combinedReturn = {}
            for(const data_ of _response){
                for(const [reviewer_,stats_] of Object.entries(data_.reviews)){
                    //Define the empty return object for the user: initialize values to 0;                        
                    combinedReturn[reviewer_] = combinedReturn[reviewer_]||{totalSkips:0, medianSkip:0, totalclips:0, logged_hours:0};
                    //Add the median skip time as a weighted sum
                    combinedReturn[reviewer_].medianSkip += stats_.maxSkipTime.count*stats_.maxSkipTime.median;                    
                    //Track the total number of clips that were reviewed
                    combinedReturn[reviewer_].totalSkips += stats_.maxSkipTime.count;                    
                    combinedReturn[reviewer_].totalclips += stats_.totalclips;     
                    //Sum the total amount of time the user was logged in
                    combinedReturn[reviewer_].logged_hours += stats_.logged_hours;     
                    //Record/update the username in the object
                    combinedReturn[reviewer_].username = reviewer_;
                }
            }//end of iteration of the multi query responses

            //Average the results to provide a single median time
            for(const [reviewer_,stats_] of Object.entries(combinedReturn)){
                combinedReturn[reviewer_].medianSkip /= combinedReturn[reviewer_].totalSkips; //completes the weighted average calculation
            }
            //Send the combined results to be ranked and formated for display
            let userReviewData = processReviewTimes(combinedReturn || {});    
            // console.log("Combined: ",combinedReturn,userReviewData);
            //Update the table data that is dispayed (this trigger a re-render)
            setTableData(userReviewData);
        } catch (error) {
            console.log("Error in combine: ",error);
        }
    }//end fetchResponse

    /**
     * @brief Listen for series selection
     */
    const render =() => {
        // console.log("Table: ",tableData,tableCols);
        return  <React.Fragment>
                    <div className="title">Worst Performing Median Review Time</div>
                    {tableData.length===0? <Spinner />:
                        <BootstrapTable keyField='rank' // a react specific thing that sets the 'key' for each row in the table
                                                // react uses keys to keep track of identity when things change
                            data={tableData} // <-- IMPORTANT: this is the actual data being displayed
                            columns={tableCols}
                            striped={false} // sets every other row a different shade, makes it easier for the eye to
                            rowStyle={ rowStyle}
                            hover={false}   // sets a hover effect, so the background color of a row changes when the
                            // mouse is over it. This signals to the user that the row is clickable
                            classes={"averagereviewtime"} // sets a CSS class so we can style this table specifically
                            bootstrap4 = {true}
                            defaultSorted={[ {dataField: 'rank', order: 'asc'},]} // how things should be sorted by                             
                            // defaultSorted={[ {dataField: 'username', order: 'desc'},]} // how things should be sorted by                             
                        />                    
                    }
                    <div className="spacer"/>                    
                </React.Fragment>
    }//end render method
   
    return render();
};

///////////////////////////////////////////////////////
// Extenal exports
///////////////////////////////////////////////////////
export { AverageReviewTime };
