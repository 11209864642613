

import * as moment from 'moment';
import {commaizeNumber} from '../Util.js'


/**
 * @brief Helper function to maximum number of characters in a list of labels
 */ 
const getMaxChars = function(_xData) {
    let maxChars = 0;
    _xData.forEach(item => maxChars = Math.max(maxChars,item.length));
    return maxChars;
}; //end getMaxChars

// /**
//  * @brief Helper function to maximum value in a list of labels
//  */ 
// const getMaxValue = function(_yData) {
//     let maxValue = 0;
//     _yData.forEach(item => maxValue = Math.max(maxValue,item));
//     return maxValue;
// }; //end getMaxValue

/**
 * @brief Helper function to get the x-axis name from the filter settings
 */ 
const getXAxisTitle = function(_bin) {
    switch(_bin) {
        case 'Staff':
            return 'Staff Member';
        case 'Month':
            return 'Month';
        case 'Week':
            return 'Week';
        case 'Day':
            return 'Date';
        default:
            return _bin;
    } //end switch
}; //end getXAxisTitle

/**
 * @brief Helper function to trim data to the input min and max
 */ 
const trimData = function(_data, _xmin, _xmax, _smin, _smax){
    let data = {};
    // Loop over all dataseries
    for (const [key] of Object.entries(_data)) {
        // Handle the secondary axis
        if(key === 'xdatasecondary') {
            data[key] = _data[key] == null ? null : _data[key].slice(_smin,_smax); 
        } else{
            // Auto scale is disabled for multiple axes
            data[key] = _data[key] == null ? null : _data[key].slice(_xmin,_xmax);
        }
    }
    
    return data;
}

/**
 * @brief Helper function to configure an echarts option block with common styling
 */ 
export const getCommonOptions = function(_title, _data, _bin, _staff, _xmin, _xmax) {
    // console.log("Options: ",_title, _bin, _staff, _xmin, _xmax);
    // Get the offset of the x and y axes
    let xOffset = getMaxChars(_data.xdata);
    let yOffset = 6;//String(getMaxValue(_data.total)).length;

    // Compute local flags
    let bSecondary = _data.xdatasecondary != null;                    // We have a secondary axis
    let bHorizontal= bSecondary                                       // We do not want to rotate the axis
        || _bin === 'Week'
        || _data.xdata.length <= 15;   
    let bLong  = !bSecondary && (_data.xdata.length >= 42             // We have an axis with too much data
        || (_bin ==='Week' && _data.xdata.length >= 7));
    let bStaff = _staff != null && _staff !== 'All';                  // We have a staff filter
    let bSplitWeek = _bin === 'Week' && (bLong || 
        (bSecondary && _data.xdatasecondary.length >= 7));            // Split the week label into rows
    let bBigLabel = _bin === 'Staff';                                 // Increase the size of the label

    // Define an axis label formatter
    let weekFormatter = (value) => {
        return value.replace(/ – /g, "\n– ") 
    };

    // Define options shared across all charts
    let options = {
        title : {
            text: [_title 
                + (bStaff ? ` by ${_staff}` : '') 
                + (_bin === 'Staff' ? ' per Staff Member' : (bSecondary ? ' per Staff Member over Time' : ' over Time'))],
            left: 'center',
            backgroundColor: 'rgb(255, 255, 255)',
        },
        tooltip: {
            show: true,
            trigger: 'axis',
            textStyle: {fontSize : 16},
        },
        legend: {
            z:4,
            top: '35',
            textStyle: {fontSize : 14},
            backgroundColor: 'rgb(255, 255, 255)',
        },
        grid: {
            top: 80,
            bottom: bSecondary ? xOffset*16.5 : (bSplitWeek ? 87.5 : (bHorizontal || bLong ? 67.5 : xOffset*8.75)),
            left: 80,
            right: 40,
        },
        xAxis:[{
            type: 'category',
            data: _data.xdata,
            name: (bSecondary ? "Staff Member and ": "") + getXAxisTitle(_bin),
            nameLocation: 'center',
            nameTextStyle: { fontWeight: 'bold', fontSize: 16 },
            nameGap: bSecondary ? xOffset*13.5 : (bSplitWeek ? 55 :(bHorizontal || bLong ? 40 : xOffset*6)),
            position: 'bottom',
            ...(bLong && {axisTick : {alignWithLabel: true}}),
            axisLabel: {
                fontSize: bBigLabel ? 18 : 16,
                ...(bSecondary && {fontWeight : 'bold'}),
                fontFamily: 'monospace',
                ...(!bLong && {interval: 0}),
                ...(bSecondary && {rotate : 90}),               //rotate primary axis vertical if secondary exists
                ...(!bHorizontal && !bLong && {rotate: 30}),    //apply 30 degree rotation when not horizontal
                margin: bSecondary ? 10 : (bHorizontal ? 15 : xOffset),
                ...(bSplitWeek && {formatter: weekFormatter}),
            }
        }],
        yAxis: {
            type: 'value',
            nameLocation: 'center',
            nameTextStyle: { fontWeight: 'bold', fontSize: 16 },
            nameGap: 30+yOffset*4,
            axisLabel: {
              fontSize: 16,
              fontFamily: 'monospace',
            }
        },
    };

    // Conditionally add the second axis
    if(bSecondary) {
        options.xAxis.push({
            type: 'category',
            data: _data.xdatasecondary,
            position: 'bottom',
            axisLabel: {
                interval: 0,
                inside: true,
                margin: bSplitWeek ? -xOffset*12.75 : -xOffset*12.5,
                fontSize: 16,
                ...(bSplitWeek && {formatter: weekFormatter}),
            },
            axisTick:  {
                length: xOffset*11,
            }
        });
    } 
    return options;
}; //end getCommonOptions

/**
 * @brief Helper function append the data labels
 */ 
const addDataLabels = function(_options, _data, _bin) {
    // Do not add data labels
    if(_data.xdata.length >= 42) {return _options;}

    // Define a formatter to remap the label of the last series to the series sum
    const totalFormatter = (series) => {
        return (param) => { return Number(series.total[param.dataIndex]) > 0 ? commaizeNumber(series.total[param.dataIndex]) : ''}
    };

    // Define a formatter to use thousands separator and hide zeros
    const seriesFormatter = () => {
        return (param) => { return Number(param.value) > 0 ? commaizeNumber(param.value) : '' }
    };

    // Add the data labels
    if(_bin !== 'Month' || _data.xdata.length > 8) {
        // Add a sum to the last series
        _options.series[Math.max(_options.series.length -1,0)].label = {
            show: true,
            formatter: totalFormatter(_data),
            fontSize: 16,
            fontWeight: 'bold',
            fontFamily: 'monospace',
            color: 'black',
            position: 'top'
        }
    } else {
        // Add a total to all series
        _options.series.forEach((item) => {
            item.label = {
                formatter: seriesFormatter(),
                show: true,
                fontSize: 16,
                fontWeight: 'bold',
                fontFamily: 'monospace',
                color: 'black',
                position: 'top'
            }
        })
    }
    return _options;
}

///////////////////////////////////////////////////////
// Local helper functions
///////////////////////////////////////////////////////

/**
 * @brief Helper function to return a formatted array of days between the dates
 */ 
export const enumerateDays = function(_startDate, _endDate) {
    let dates = [];

    // Push the start date onto the array
    let currDate = _startDate.clone();
    dates.push(currDate.clone().format('MMM DD, YYYY').toString());

    // For each date less than the end date
    while(currDate.add(1, 'days').diff(_endDate) <= 0) { // Add mutates the current date
        dates.push(currDate.clone().format('MMM DD, YYYY').toString());
    }
    return dates;
}; 

/**
 * @brief Helper function to return a formatted array of days or months between the dates
 */ 
export const enumerateMonths = function(_startDate, _endDate) {
    let dates = [];

    // Push the start date onto the array
    let currDate = _startDate.clone();
    dates.push(currDate.clone().format('MMMM YYYY').toString());

    // For each date less than the end date
    while(currDate.add(1, 'month').diff(_endDate) <= 0) { // Add mutates the current date
        dates.push(currDate.clone().format('MMMM YYYY').toString()); 
    }
    return dates;
}; 

/**
 * @brief Helper function to return a formatted array of weeks between the dates
 */ 
export const enumerateWeeks = function(_startDate, _endDate) {
    let dates = [];
    let lastDate = _endDate.clone().add(2,'days'); // Offset from ISO Week
    let currDate = _startDate.clone();

    while(currDate.diff(lastDate) < 0) {
         // Get the start of the week (First day of ISO week is Monday, First day of EDGE3 week is Sunday)
        let startDate = moment.max([currDate.clone().startOf('isoWeek').subtract(1,'days'),_startDate]);
        // Move the index to the beginning of next week
        currDate.endOf('isoWeek').add(2,'days'); 
        // Get the end of the week
        let endDate = moment.min([currDate.clone().subtract(3,'days'),_endDate]);
        // Push a string concatenating the two dates
        let string = startDate.format('MMM DD, YYYY').toString()+" – "+endDate.format('MMM DD, YYYY').toString();
        // Handle special case where start date is also beginning of the wee
        if(endDate.diff(startDate) >= 0) {dates.push(string)};
    }
    return dates;
};

// Configure the Graph Title

export const getStaffName = function(_details, _username) {
    try {
        let staffName = _details.filter(staff_=>{return staff_.username===_username})
        // console.log("Staff name: ",staffName);
        if(staffName){
            // console.log("  Name: ",staffName[0].name)
            if(!staffName[0].name){return _username;}
            return staffName[0].name;
        }
    } catch (error) {
        return _username;
    }
}


export class StaffPerformanceMessages{
    /**
     * Initialize the member variables that are tracked by the class
     */
    constructor(){
        this.subscribers=[]; //Set instances that will be notified of a new message on the websocket
        this.username = 'unset';
    }

    //Forward the clip to all listeners
    broadcast(_msg){
        //Iterate through the listeners to see who want this event type:
        for(const sub_ of this.subscribers){
            sub_.callback(_msg);
        }
    }// end init
    
    /**
     * Register a component/class with the websocket, this will enable any message of the desired type
     * to be forwarded to the registered class
     * @param {*} _data : object holding details of the registration:
     *  -name: unique name for the connection (example: LiveTab)
     *  -onMessage: callback function to recieve the message
     *  -type: types of messages to listen for (comma seperated list of multiple types)
     * @returns 
     */
    subscribe(_data){
        // console.log("Try Register listener:" ,_data, _data.name);
        //Check the min details are met
        if(!_data.name){return;}
        if(!_data.callback){return;}
        //If met then add to the list
        this.subscribers.push(_data);
        // console.log("Subs: ",this.subscribers);
    }//end register()

    /**
     * remove a component from the registerd listeners
     * @param {*} _data : the name of the class/component to remove
     */
    release(_data){
        //Iterate over the registered users and remove the requested user:
        const index = (this.subscribers||[]).findIndex(elem_ => elem_.name === _data.name);
        if (index > -1) { // only splice array when item is found
            this.subscribers.splice(index, 1); // 2nd parameter means remove one item only
        }
    }//end release()
    /**
     */
    close(){
        console.log("Closed StaffPerformanceMessages called");
    }//end close

}//end StaffPerformanceMessages