///////////////////////////////////////////////////////
// Imports and constants
///////////////////////////////////////////////////////
import React, { useEffect } from 'react';
import './StaffPerformance.css'; // Link to the CSS Style sheet
import BootstrapTable from 'react-bootstrap-table-next';

/*
* @brief Load a BootStrap table with default values, incorporate support to be wrapped by the withLoadingAnimation HOC
*/
export const WrappedTable = ({ 
    data
    ,columns
    ,...rest}) => {

    useEffect(() => {
        //Check if data was received
        if(rest.loadingComplete && data && data.length>0){
            rest.loadingComplete(true);
        }
    },[data]) //listen for changes to inputs

    function render(){
        const rowStyle = (row, rowIndex) => {
            let rowcolor = '#00afed05' 
            if(rowIndex%2 ===0){rowcolor = '#00afed20' }
            let returnObj = {
                backgroundColor: rowcolor,
            };
            return returnObj;
        };

        return ( 
            <BootstrapTable 
                data={rest.hideRender?[]:data} // <-- IMPORTANT: this is the actual data being displayed
                columns={columns}
                rowStyle={ rest.rowsStyle||rowStyle}
                classes={rest.classes} // sets a CSS class so we can style this table specifically
                keyField= {rest.keyFeild||'rank'} // a react specific thing that sets the 'key' for each row in the table
                                                            // react uses keys to keep track of identity when things change
                striped={rest.striped||false} // sets every other row a different shade, makes it easier for the eye to
                hover={rest.hover||false}   // sets a hover effect, so the background color of a row changes when the
                                // mouse is over it. This signals to the user that the row is clickable
                bootstrap4 = {rest.bootstrap4||true}                
                defaultSorted = {rest.defaultSorted}
            />
        );
    }
    return render();

}//end WrappedTable
   