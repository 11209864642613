
import { Auth, API } from 'aws-amplify';
import moment from 'moment';

/**
 *  Utilitiy class to auto optimize a query by splitting the date parameters 
 *  Old data that is not expected to change can be cached and not requeried in future refreshes
 */
class TimeOptimizedQueryClass{
    /**
     * Initialize the member variables that are tracked by the class
     */
    constructor(){
        this.queryConfigs={}; //Set of configurations of the added queries
    }

    /**
     * Define a query and how to handle the request to query
     * @param {*} key : name of the query
     * @param {*} config: define the time splits and the callback functions to execute the query
     * @returns 
     */
    addQuery(key, config){
        // console.log("create new query: ",key,config);
        try {
            this.queryConfigs[key] = Object.assign(this.queryConfigs[key]||{},config);
            return {status:"success"};
        } catch (error) {
            console.log("Failed to create: ",error);
            return {status:"fail"};
        }
    }

    /**
     * Split the query by time, by default the older data will be split into 30 days increments
     * This can be configured by setting the splitDays in the initial configuration
     */
    splitQueryByTime(key,_data,_options){
        
        try {
            let queryConfiguration = this.queryConfigs[key];
    
            //get the desired time segmentation:
            let timeAmount = queryConfiguration.timeAmount;
            let timeUnit = queryConfiguration.timeUnit;
            let splitDays = queryConfiguration.splitDays || 30;
    
            //Need to split the query up into set of dates
            let querySets = [];

            let localDate = _options.date;
            if(!localDate && _options.filters){
                localDate= {
                    start:_options.filters.startDate,
                    end:_options.filters.endDate
                }
            }
            

            //Define the total date range of the query
            let desiredEndDate = localDate.end? moment(localDate.end) : moment().utc();
            let startDate = localDate.start;
            //Set the cutoffDate, this was the defined old vs new data criteria from the configuration
            let cutoffEndDate = (localDate.end ? moment(localDate.end) : moment().utc()).subtract(timeAmount,timeUnit);
            //Determine how many days are in the old data block
            let daysInFilter = moment.duration((moment(cutoffEndDate)).diff(startDate)).asDays();
            //Start iteratively slicing the old date block into 30 days increments            
            let splitStart = moment(startDate);
            let splitEnd = moment(splitStart)
            while(daysInFilter > splitDays){
                //update the test poins
                splitStart = moment(splitEnd);
                splitEnd = moment(splitStart).add(timeAmount,timeUnit);
                //Split this into groups of n days:                
                if(splitEnd.isBefore(cutoffEndDate)){
                    let toPush = {..._options,...{date:{
                                                            start: splitStart.format('YYYY-MM-DD'),
                                                            end: splitEnd.format('YYYY-MM-DD'),
                                                        }
                                                    }};
                    querySets.push(toPush);
                }else{ //ran into the current cutoff date
                    // console.log("Not full month after: ",splitEnd.format('YYYY-MM-DD'));
                }
                daysInFilter = moment.duration((cutoffEndDate).diff(splitEnd)).asDays();
            }
            //Add the last chunck  till current/end
            let toPush = {..._options,...{date:{
                                        start: splitEnd.format('YYYY-MM-DD'),
                                        end: localDate? desiredEndDate.format('YYYY-MM-DD'):null,
                                        }
                                    }};
            querySets.push(toPush);
            // console.log("Query Set: ",...querySets);
            querySets.reverse();
            //return the set of date ranges to query with the API
            return querySets;
        } catch (error) {
            console.log("Fail to split:" ,error, _options);
            return [];
        }
    }
    // Helper method to determine if cached data is available
    isCachedResponse(key,_set){
        return null;
    }

    /**
     * Execute a query, the query date range is auto split into the date blocks and executed in parallel
     * @param {*} key: name ofthe query to executed
     * @param {*} _data : data to pass to the query strings
     * @param {*} _options : configured values in the query strings
     * @returns 
     */
    query(key,_data,_options){
        // console.log("Loaded query: ",key, _data, _options,this.queryConfigs);
        try {
            if(!this.queryConfigs[key]){return {status:"fail"};}
            let queryConfiguration = this.queryConfigs[key];

            // console.log("Load config: ",this.queryConfigs[key]);
            //Set up the segmented query using the date
            let querySet = this.splitQueryByTime(key,_data,_options);

            // console.log("Query sets:" ,querySet);
            // return;
            
            //Helper method to call on each segmented date block to run the attached query
            const executeQuery = (_set)=>{
                return new Promise(async (resolve, reject) => {
                    const authResponse = await Auth.currentSession();
                    if(authResponse){
                        let body = {token: authResponse.idToken.jwtToken};                        
                        body = Object.assign(body,_set); //append the options to the query parameters

                        //Start query by calling the attached query callback
                        let queryPromise = queryConfiguration.queryFN(body);
                        resolve(queryPromise); //complete the promise with the query returns
                    }else{
                        reject({error:true, msg: authResponse})
                    }
                });
            }//end of query promise iteration generation

            //Iterate over each segmented date block and created a promise to fulfill            
            let queryPromises = querySet.map((set_,idx) => {
                //Fire off the timeouts one at a time, to prevent overloading the API call, or the webworker
                let cacheResponse =  this.isCachedResponse(key,set_);
                if(cacheResponse){
                }else{
                    //Pass the date block to the query
                    return executeQuery(set_);
                }
            });//end the map

            //Wait for all promises to complete before continuing
            Promise.all(queryPromises).then(promiseReturns=>{
                //Send all results back to the callback to be combined
                queryConfiguration.responseFN(promiseReturns,_data);
            })
            return {status:"success"};
        } catch (error) {
            console.log("Failed to create: ",error);
            return {status:"fail"};
        }
    }//end of query
}//end TimeOptimizeQuery
export const TimeCachedQuery = new TimeOptimizedQueryClass(); //return a singleton instance to the callers

