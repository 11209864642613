import React,{useState,useEffect,useRef} from 'react';

import { DO_PERFORMANCE_TIMING} from '../Util-access.js';

export function useBenchMark({ markName, enabled },callback) {
  const startTimeRef = useRef(0);
  const renderTimeRef = useRef(0);
  const renderTimeLoginRef = useRef(0);
  const reported = useRef(0);
  
  useEffect(() => {
    if (!enabled || !DO_PERFORMANCE_TIMING) {
        return;
    }
    startTimeRef.current = performance.now();
    reported.current = 1;
    // console.log("useEffect update (start)",)
    performance.mark(markName+"start");

    const intervalId = setInterval(() => {
      let sinceUpdate = performance.now() - startTimeRef.current;
      if(sinceUpdate>5000){
        if (callback && reported.current===0) {
          // console.log("Send timeTaken ",renderTimeRef.current);
          reported.current = 1;
          callback({
            renderSinceLogin: renderTimeLoginRef.current,
            render: renderTimeRef.current,
          });
          
        }
      }
      // 
      // setTimeSinceMount((prevTime) => prevTime + 1);
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  },[]);

  useEffect(() => {
    reported.current = 0;
    // console.log("useEffect update")
    
    if (!enabled || !DO_PERFORMANCE_TIMING) {
        return;
    }

    // setRenderTime(timeTaken);
    // Queues a requestAnimationFrame and onmessage
    runAfterFramePaint(() => {
      // Set a performance mark shortly after the frame has been produced.
      performance.mark(markName+"end");

      let elapsedTime =  performance.measure(markName+"-render",markName+"start",markName+"end").duration;
      // renderTimeRef.current = endTime - startTimeRef.current;
      renderTimeRef.current = elapsedTime;
      renderTimeLoginRef.current = performance.measure(markName+"-renderlogin","signOn",markName+"end").duration;
      
      // console.log("Render completed",elapsedTime);
    });
    

    
  });

  /**
   * Add into the component when an API call is made to benchmark time to fetch
   * @param {*} param0 
   */
  function benchmark_query({name,type='end'}){
    if (!enabled || !DO_PERFORMANCE_TIMING) {
        return;
    }
    let tmpName = `${name}-${type}`
    switch(type){
      case 'start':{
        // console.log("Stamp on name ",tmpName);
        performance.mark(tmpName);
      }break;
      case 'end':{
        performance.mark(tmpName);
        let startTime = markName+"start";
        if (window.performance.getEntriesByName(`${name}-start`, 'mark').length > 0) {
          startTime = `${name}-start`;
        }
        let elapsedTime =  performance.measure(name+"-query",startTime,tmpName).duration;
        console.log(`Fetch ${name}:`,elapsedTime);
      }break;

    }
  }

  return {benchmark_query};
}

function runAfterFramePaint(callback) {
  requestAnimationFrame(() => {
      const messageChannel = new MessageChannel();

      messageChannel.port1.onmessage = callback;
      messageChannel.port2.postMessage(undefined);
  });
}

/*
* @brief Setup the performance measurement for a widget, must be called before all others
*
* @param that The widget itself
* @param name A unique name that should be used for the output performance counters (marks/measures)
*/
export function setupBenchmark(that, markName, hasData) {
  // console.log("test bind: ",that,nammarkNamee);
  if (!DO_PERFORMANCE_TIMING) {
    return;
  }
  that.perfName = markName;
  // // that._origUNSAFE_componentWillMount = that.UNSAFE_componentWillMount;
  // // that._origComponentWillUpdate = that.componentWillUpdate;
  // that._origComponentDidMount = that.componentDidMount;
  // that._origComponentDidUpdate = that.componentDidUpdate;
  that._origComponentDidMount = that.componentDidMount;
  that._origComponentDidUpdate = that.componentDidUpdate;

  that.componentDidMount = function() {

    that._origComponentDidMount && that._origComponentDidMount();
    // console.log("on Mount");
    perfDidMount(that);
  }.bind(that);

  that.componentDidUpdate = function() {
    that._origComponentDidUpdate && that._origComponentDidUpdate();
    perfDidUpdate(that, hasData);
  }.bind(that);

}

function perfDidMount(that) {
  performance.mark(that.perfName+"start");
}
function perfDidUpdate(that) {
  
  runAfterFramePaint(() => {
    // Set a performance mark shortly after the frame has been produced.
    window.performance.mark(that.perfName+'end');
    that.perf_render      = window.performance.measure(that.perfName+'render',that.perfName+'start', that.perfName+'end').duration;
    that.perf_renderLogin = window.performance.measure(that.perfName+'renderlogin','signOn', that.perfName+'end').duration;
    // console.log(`${that.perfName}-Update:` ,that.perf_render, that.perf_renderLogin);
    
    // console.log("Render completed",elapsedTime);
  });
}

export function perfQuery(that,name, type) {
  if (!DO_PERFORMANCE_TIMING) {
    return;
  }
  let tmpName = `${name}-${type}`
    switch(type){
      case 'start':{
        // console.log("Stamp on name ",tmpName);
        performance.mark(tmpName);
      }break;
      case 'end':{
        performance.mark(tmpName);
        let startTime = that.perfName+"start";
        if (window.performance.getEntriesByName(`${name}-start`, 'mark').length > 0) {
          startTime = `${name}-start`;
        }
        let elapsedTime =  performance.measure(name+"-query",startTime,tmpName).duration;
        let elapsedTimeLogin = window.performance.measure(name+"-queryLogin",'signOn', tmpName).duration;
        
        console.log(`Fetch ${name}:`,elapsedTime,elapsedTimeLogin);
      }break;

    }
}
