
import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import * as moment from 'moment';

import { Auth, API } from 'aws-amplify';

import './PhoneInterface.css';

import { Dropdownlist } from '../Wrappers/Dropdownlist.js';
import logos from '../logos.js';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import BootstrapTable from 'react-bootstrap-table-next';
// import Call icons from icon packs
import { IoCallOutline } from 'react-icons/io5';
import { BiMicrophone,BiMicrophoneOff,BiErrorAlt } from 'react-icons/bi';


/*
* @brief This is an expanded view on a Card, used on the HR Review page
*
* This view allows the user to see all comments on the card, and to add
* comments. It also allows viewing the video for the infraction in
* question.
*/
export class PhoneInterface extends PureComponent {
    constructor(props) {
        super(props);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.onRemoveSubject = this.onRemoveSubject.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.confirmClose = this.confirmClose.bind(this);

        this.onMutePress = this.onMutePress.bind(this);
        this.onStartCall = this.onStartCall.bind(this);
        this.onEndCall = this.onEndCall.bind(this);
        this.onNoAnswer = this.onNoAnswer.bind(this);
        this.onHungUp = this.onHungUp.bind(this);
        this.onCallIssue = this.onCallIssue.bind(this);

        this.statusfeedback = this.statusfeedback.bind(this);

        //Render breakouts:
        this.renderContacts = this.renderContacts.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.renderPhoneSection = this.renderPhoneSection.bind(this);
        this.renderFooter = this.renderFooter.bind(this);

        this.state = {
            shown: true,
            contactName:null,
            selectedNumber:null,
            contactNames:[],
            tags: this.props.tags,
            subjectTags: [],
            notes: null,
            bMuted: false,
            callStarted: false,
            callEnded: false,
            noAnswer:false,
            currentUsername: null,
            colorBefore:null,
            colorAfter:null,
            tabViews: ['main'],
            activeView:'main',
            mTableColumns:[],
            mTableData:[],
            assetStates:{},
            startCallTime:null,
        };
    }
    
    componentDidMount() {
        // console.log("Open Phone interface: ",this.props );
        this.getApiCall();   

        if(this.props.tags && (this.props.tags.includes("Offline") || this.props.tags.includes("System Warning"))){
            this.setState({tabViews:['main','status']})
        }

        //Format the tags:
        let tagsToAdd=[];
        (this.props.tags||[]).map( (tag_,idx) =>{
            tagsToAdd.push(<SubjectDisplay type={tag_} text={tag_} key={tag_+idx} onRemoveInfraction={this.onRemoveSubject} />);
        });

        let cols = [
            {dataField: 'assetid', text: 'Asset',editable:false, sort:true,headerStyle: () => {return { width: "24%",whiteSpace:"center",textAlign:"center"};}},            
            {dataField: 'status', text: 'Status',editable:false,sort:true,formatter:this.statusfeedback, headerStyle: () => {return { width: "65%",whiteSpace:"unset",textAlign:"center"};}},
        ];

        //Get the asset list from the props and create a data set for the table
        let tableData = [];
        let assetList = this.props.assetid.split(','); //create an array from the csv string
        for(const asset_ of assetList){
            console.log("Asset to add: ",asset_);
            tableData.push({
                assetid:asset_,
                status: 0,
                id:asset_+"-key",
            })
        }

        //Default notes: 
        let notesOptions = [];        
        notesOptions.push( "The asset is running but appearing offline in the Connectivity tab");
        notesOptions.push( "The asset is not running");
        notesOptions.push( "The asset is in maintenance");
        notesOptions.push( "Dispatch Unavailable");
        notesOptions.push( "N/A");

        
        this.setState({subjectTags:tagsToAdd,mTableColumns:cols,mTableData:tableData,notesOptions:notesOptions});
    }

    /*
    * @brief Define what is rendered in the remove user column
    */
    statusfeedback(cell,row){
        const radioChange = (data) => {
            //get the current state of the assets:
            let currentStates = {...this.state.assetStates};
            // console.log("change ",data,row.assetid,currentStates);
            if(!currentStates[row.assetid]){currentStates[row.assetid]={state:"Unknown"}}
            currentStates[row.assetid].state = data;
            // console.log("Update assetState: ",currentStates);
            this.setState({assetStates:currentStates}); //update the state with the new data
        }//end confirmClose

        //current state of the button?
        let currentState = 'Unknown';
        try {
            if(this.state.assetStates[row.assetid]){
                currentState = this.state.assetStates[row.assetid].state;
            }
            
        } catch (error) {}


        return  <div className='status-row'>
                    <div><input type="radio" value="Offline" name={row.assetid+"radio"} onChange={e=>radioChange(e.target.value)} checked={this.state.assetStates[row.assetid] && currentState==='Offline'}/> Offline</div>
                    <div><input type="radio" value="Running" name={row.assetid+"radio"} onChange={e=>radioChange(e.target.value)} checked={this.state.assetStates[row.assetid] &&currentState==='Running'}/> Running</div>
                    <div><input type="radio" value="Maintenance" name={row.assetid+"radio"} onChange={e=>radioChange(e.target.value)} checked={this.state.assetStates[row.assetid] &&currentState==='Maintenance'}/> Maintenance</div>
                </div>  
    };
   
    /*
    * @brief The definition of the API call that we need to do to display this list
    */
    getApiCall() {
        Auth.currentSession().then(
            (auth) => {
                this.setState({currentUsername: auth.idToken.payload['cognito:username']});
                let apiName = "TrifectaAPI";
                let path = "/getSiteDetails";
                let myInit = {
                    body: {
                    token: auth.idToken.jwtToken,
                    site:this.props.siteDetails[0].site,
                    clientid: this.props.groupconfig.group,
                    mode:'fetch-call'
                    }
                };
                API.post(apiName, path, myInit).then(this.updateData);              
            })

        Auth.currentSession().then(
            // console.log("Trigger getMonitor call:");
            (auth) => {
                let apiName = "TrifectaAPI";
                let path = "/getMonitorState";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode: "fetch",
                        group: this.props.groupconfig.group,
                        sites: this.props.siteDetails[0].site,
                        asset:this.props.assetid,
                    }
                };       
                return API.post(apiName, path, myInit).then((data)=>{
                    console.log("Get State: ",data);
                    try {
                        
                        let assetData = data.data[0];
                        console.log("Set state: ",assetData.state)
                        switch(assetData.state){
                            default: {this.setState({colorBefore:'N/A',colorAfter:'N/A'});} break;
                            case 1: {this.setState({colorBefore:'Green'});} break;
                            case 2: {this.setState({colorBefore:'Green'});} break;
                            case 3: {this.setState({colorBefore:'Yellow'});} break;
                            case 4: {this.setState({colorBefore:'Yellow'});} break;
                            case 5: {this.setState({colorBefore:'Red'});} break;
                        } //end switch  
                    } catch (error) {
                        // console.log("Error? ",error);
                        this.setState({colorBefore:'N/A',colorAfter:'N/A'});
                    }
                    
                });
            });


    }
    /*
    * @brief Called to remove an element from the subject list
    */
    onRemoveSubject(data) {
        console.log("Found remove:" ,data);
    }
    /*
    * @brief Called to update the data we're displaying
    */
    updateData(data) {
        console.log("Call data: ",data);
        try {
            if(data.site){
                let names = data.site.contactnames.split(',');
                names = names.sort();
                names.push('Other');
                this.setState({contactNames:names})
            }    
        } catch (error) {
            
        }
        
    }
    /*
    * @brief Called to remove an element from the subject list
    */
    onMutePress(_data) {

        
        // console.log("Found remove:" ,_data);
        if(this.state.selectedNumber && this.state.selectedNumber.number.length > 1){
            this.setState({bMuted: !this.state.bMuted})
        }
        const link = document.createElement('a'); //create a temporary link to use as the download        
        // link.href = "E3StartCall:+19286075448"; //set the content of the file to download        
        link.href = "E3MuteToggle:"; //set the content of the file to download        
        document.body.appendChild(link); //add the temporary link to the document (webpage)
        link.click(); //automatically click the link 
        document.body.removeChild(link); //remove the temporary link used to download the file 
        
    }
    /*
    * @brief Called to when the start call button is pressed
    */
    onStartCall(){
        if(!this.state.selectedNumber || this.state.selectedNumber.number.length <= 1){ return;}
        // <a href="skype:echo123?call"></a>

        try {

            let callString = "E3StartCall:"+this.state.selectedNumber.number;
            console.log("Call call start: ",this.state.selectedNumber.number)
            const link = document.createElement('a'); //create a temporary link to use as the download        
            // link.href = "E3StartCall:+19286075448"; //set the content of the file to download        
            link.href = callString; //set the content of the file to download        
            // link.href = "E3StartCall:echo123";
            document.body.appendChild(link); //add the temporary link to the document (webpage)
            link.click(); //automatically click the link 
            document.body.removeChild(link); //remove the temporary link used to download the file 

            this.setState({callStarted:true,callEnded:false,startCallTime:moment()})    

        } catch (error) {
            console.log("Failed to start call: ",error);
        }

        

    }
    /*
    * @brief Called to when the end call button is pressed
    */
    onEndCall(){
        if(!this.state.selectedNumber || this.state.selectedNumber.number.length <= 1){ return;}

        console.log("Call call end:")
        const link = document.createElement('a'); //create a temporary link to use as the download        
        // link.href = "E3ChromeOpen:+19286075448?call"; //set the content of the file to download        
        link.href = "E3EndCall:"; //set the content of the file to download        
        document.body.appendChild(link); //add the temporary link to the document (webpage)
        link.click(); //automatically click the link 
        document.body.removeChild(link); //remove the temporary link used to download the file 

        this.setState({callEnded:true})
    }
     /*
    * @brief Called to when the no answer button is pressed
    */
     onNoAnswer(){
        if(!this.state.selectedNumber || this.state.selectedNumber.number.length <= 1){ return;}

        console.log("Call call end:")
        const link = document.createElement('a'); //create a temporary link to use as the download        
        // link.href = "E3ChromeOpen:+19286075448?call"; //set the content of the file to download        
        link.href = "E3EndCall:"; //set the content of the file to download        
        document.body.appendChild(link); //add the temporary link to the document (webpage)
        link.click(); //automatically click the link 
        document.body.removeChild(link); //remove the temporary link used to download the file 

        //Trigger handle submit:
        setTimeout(()=>{this.setState({callEnded:true, noAnswer:true},this.handleSubmit("No Answer"))},3000)
    }
    onHungUp(){
        if(!this.state.selectedNumber || this.state.selectedNumber.number.length <= 1){ return;}

        console.log("Call call end:")
        const link = document.createElement('a'); //create a temporary link to use as the download        
        // link.href = "E3ChromeOpen:+19286075448?call"; //set the content of the file to download        
        link.href = "E3EndCall:"; //set the content of the file to download        
        document.body.appendChild(link); //add the temporary link to the document (webpage)
        link.click(); //automatically click the link 
        document.body.removeChild(link); //remove the temporary link used to download the file 

        //Trigger handle submit:
        setTimeout(()=>{this.setState({callEnded:true, noAnswer:true},this.handleSubmit("Hung Up"))},3000)
    }
    onCallIssue(){
        if(!this.state.selectedNumber || this.state.selectedNumber.number.length <= 1){ return;}

        console.log("Call call end:")
        const link = document.createElement('a'); //create a temporary link to use as the download        
        // link.href = "E3ChromeOpen:+19286075448?call"; //set the content of the file to download        
        link.href = "E3EndCall:"; //set the content of the file to download        
        document.body.appendChild(link); //add the temporary link to the document (webpage)
        link.click(); //automatically click the link 
        document.body.removeChild(link); //remove the temporary link used to download the file 

        //Trigger handle submit:
        setTimeout(()=>{this.setState({callEnded:true, noAnswer:true},this.handleSubmit("Call Issue"))},3000)
    }
    /*
    * @brief Collect data and sync to SQL table on close
    */
    handleSubmit(_noteOverride){
        // console.log("Tags: ",...this.state)
        //Collect the data from the states:
        let dataToSubmit={};
        dataToSubmit.site = this.props.siteDetails[0].site;
        dataToSubmit.user = this.state.currentUsername;
        dataToSubmit.asset = this.props.assetid;
        dataToSubmit.infractionid = this.props.infractionid;
        dataToSubmit.subject =(this.state.tags||[]).join(",");
        dataToSubmit.contact = this.state.contactName;
        dataToSubmit.colorbefore = this.state.colorBefore;
        dataToSubmit.colorafter = this.state.colorAfter;        
        dataToSubmit.startTime = this.state.startCallTime;

        dataToSubmit.escalation = 1;
        dataToSubmit.callnumber = "";
        try {
            dataToSubmit.escalation = this.state.selectedNumber.escalation;
            dataToSubmit.callnumber = this.state.selectedNumber.number;
        } catch (error) {
            
        }
         



        if(_noteOverride){
            // console.log("Overwrite notes:" ,_noteOverride);
            dataToSubmit.notes = _noteOverride;
        }else{
            // console.log("Use selected notes: ",this.state.notes);
            dataToSubmit.notes = (this.state.notes||[]).join("|");
        }

        //Get the states:
        let stateList = ""
        for(const asset_ of Object.keys(this.state.assetStates)){
            // console.log("Asset to write: ",asset_);
            stateList+= asset_+":"+this.state.assetStates[asset_].state+"|";
        }
        stateList=stateList.slice(0,-1);
        dataToSubmit.notes+="|"+stateList;
        

        // console.log("Sync to SQL: ",dataToSubmit);

        Auth.currentSession().then(
        (auth) => {
            this.setState({currentUsername: auth.idToken.payload['cognito:username']});
            let apiName = "TrifectaAPI";
            let path = "/handlePhoneCall";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    site:this.props.siteDetails[0].site,
                    clientid: this.props.groupconfig.group,
                    mode:'set',
                    details: JSON.stringify(dataToSubmit),
                }
            };
            API.post(apiName, path, myInit).then((data)=>{
                console.log("Returned sync: ",data)
                //Close the card:
                this.props.onClose(this.state,false);
            });              
        });

        
    }
    
   
    //Handle what happens when the user click off the card
    confirmClose(){
        console.log("Close phone")
        this.props.onClose(this.state,false);
    }//end confirmClose

    /**
     * Helper method to render the contact dropdown menus on the notecard
     */
    renderContacts(){
        let numbers = [];
        let namelist = [];
        try { //numbers will be null if there are no contact numbers
            //This will prevent the card from functioning
            numbers = this.props.siteDetails[0].contactnumbers;
            namelist = this.props.siteDetails[0].contactnames.split(',');
            namelist = namelist.sort();
            namelist.push('Other');
            // console.log("Site details: ",namelist)
           
        } catch (error) {
            console.log("Error on split:" ,error);
        }

        // console.log("Numbers: ",this.state.notesOptions, this.state.notes);
        // console.log("Selected Number:" ,this.state.selectedNumber);

        return (
            <div className='details-main'>
                <div className='detail-wide'>
                    <div className="label-text">Subject: </div>
                    <div className="scrollable-list">
                        {this.state.subjectTags}
                    </div>                                 
                    
                </div>
                
                <div className='detail'>
                    <div className="label-text">Number: </div>
                    {!numbers || numbers.length < 1?null:
                        <div className='dropwrapper'>
                            <Dropdownlist
                                data={numbers}
                                dataKey='number'
                                textField='name'
                                onChange={selectedReturn => { this.setState({selectedNumber:selectedReturn})} }
                                value={this.state.selectedNumber}
                            />
                        </div>
                    }
                </div>
                <div className='detail'>
                    <div className="label-text">Contact: </div>
                    <div className='dropwrapper'>
                        <Dropdownlist
                            isDisabled={!this.state.callStarted}
                            data={namelist}
                            onChange={selectedReturn => {this.setState({contactName:selectedReturn?selectedReturn.value:null})} }
                            value={this.state.contactName}
                        />
                    </div>
                </div>
                
                
                <div className='detail-wide'>
                    <div className="label-text">Notes: </div>
                    <div className='dropwrapper'>
                        <Dropdownlist
                            isMulti
                            data={this.state.notesOptions}
                            value={this.state.notes}
                            isDisabled={!this.state.callStarted}
                            onChange={(selectedReturn) => {
                                try {
                                    //Need to convert the array object back to a array of values
                                    let toSet= selectedReturn.map((elem_)=>{return elem_.value });
                                    // console.log("Notes",selectedReturn, toSet); 
                                    this.setState({notes:toSet})
                                } catch (error) {}
                            }}//end onChange
                            
                        />
                    </div>
                </div>
            </div>
        )
    }//end renderContacts

    /**
     * Helper method to render the BootStrap table on the notecard
     */
    renderTable(){
        //=================Define the table formatting==================
        const rowEvents = {
            onClick: (e, column, columnIndex, row, rowIndex) => {  /* console.log("Row clicked: ",e,row,rowIndex, column, columnIndex); */     }
        };
        const rowClasses = (row, rowIndex) => { return 'soc-lister'; };
        // Set style for the rows in the table   
        const rowStyle = (row, rowIndex) => {
            let rowcolor = '#00afed05'
            if(rowIndex%2 ===0){ rowcolor = '#00afed20' }
            return{
                backgroundColor: rowcolor,
             
            }
        };

        //Set up the data to render in the table
        let tableColumns=[];
        let tableData = [];
        try {
            tableColumns = this.state.mTableColumns || [];
            tableData = this.state.mTableData || [];
            // console.log("Tables:" ,this.state.mTableData,tableData);
        } catch (error) {
            
        }


        return (
                <div className='details-offline'>
                    <BootstrapTable 
                        keyField='id' // a react specific thing that sets the 'key' for each row in the table
                                            // react uses keys to keep track of identity when things change
                        data={tableData} // <-- IMPORTANT: this is the actual data being displayed
                        columns={tableColumns}
                        defaultSorted={[
                                        {dataField: 'assetid', order: 'asc'}, // how things should be sorted by                                                                
                                        ]} // default when first displayed
                        striped={false} // sets every other row a different shade, makes it easier for the eye to
                                        // keep track of what data belongs to what row when scanning across
                        rowStyle={ rowStyle}
                        hover={true}   // sets a hover effect, so the background color of a row changes when the
                        //                  // mouse is over it. This signals to the user that the row is clickable
                        classes={"offline-assets"} // sets a CSS class so we can style this table specifically
                        rowEvents={rowEvents} // set what events we want to happen on rows, right now just the click
                        rowClasses={rowClasses}
                        bootstrap4 = {true}
                        detailView={true}
                        detailViewIcon={false}
                        detailViewByClick={true}
                        // pagination={ paginationFactory(paginationOptions) }
                        // selectRow={ selectRow }
                    />
                </div>
                )

    }//end renderTable

    /**
     * Helper method to render the phone icons (buttons) on the notecard
     */
    renderPhoneSection(){

        // console.log("Selected: ",bButtonsEnabled,this.state,this.state.selectedNumber,this.state.selectedNumber && this.state.selectedNumber.number.length > 1);
        let bButtonsEnabled = false;
        if(this.state.selectedNumber && this.state.selectedNumber.number.length > 1){
            bButtonsEnabled = true;
        }
        // bButtonsEnabled=true;
        let buttonStyle = {
                            visibility:'visible',
                            opacity:"0.5",
                            filter: "grayscale(1)",

                        };
             
        if(bButtonsEnabled){
            buttonStyle={visibility:'visible'}
        }

        

        let callStateStyle = {visibility:"hidden"}
        if(this.state.callStarted && !this.state.callEnded){
            callStateStyle.visibility='visible';
        }


        return (
            <div className = 'phone-section'>
                <div className = 'content'>
                    <div className='label-text'>{"EDGE3 Person: "} </div>
                    <div className='label-value'>{this.state.currentUsername} </div>  
                    <div className='label-text'>{"Assets: "} </div>
                    <div className='label-value'>{this.props.assetid} </div>  
                    
                        <div className='callstate' style={callStateStyle}> 
                            <div className='statelabel'>Call in progress </div>
                            <div class="stage">
                                <div class="dot-flashing"></div>
                            </div>

                        </div>
                </div>
                <div className = 'controls'>
                    
                    <div className = 'startcall'>
                        {bButtonsEnabled?
                            <div className='startcallbutton' onClick={this.onStartCall}><IoCallOutline className='startcallicon'/></div>
                        :
                            <div className='startcallbutton-disabled'><IoCallOutline className='startcallicon'/></div>
                        }
                        <div className='under-label' style={buttonStyle}>Start Call</div>
                    </div>
                    
                        {this.state.bMuted?
                            <div className = 'mute'>
                                
                                <div className='unmutebutton' onClick={this.onMutePress}><BiMicrophoneOff className='unmuteicon'/></div>
                                <div className='under-label' style={buttonStyle}>UnMute</div>
                            </div>
                        :
                        
                            <div className = 'mute'>
                                {bButtonsEnabled?
                                    <div className='mutebutton' onClick={this.onMutePress}><BiMicrophone className='muteicon'/></div>
                                :
                                    <div className='mutebutton-disabled'><BiMicrophone className='muteicon'/></div>
                                }
                                
                                <div className='under-label' style={buttonStyle}>Mute</div>
                            </div>
                        }
                        
                        
                    
                    <div className = 'hangup'>
                        {bButtonsEnabled?
                            <div className='endcallbutton' onClick={this.onEndCall}><IoCallOutline className='endcallicon'/></div>
                        :
                            <div className='endcallbutton-disabled'><IoCallOutline className='endcallicon'/></div>
                        }
                        
                        <div className='under-label' style={buttonStyle}>End Call</div>
                    </div>
                    <div className = 'noanswer'>
                        {bButtonsEnabled?
                            <div className='noanswerbutton' onClick={this.onNoAnswer}>
                                <IoCallOutline className='noanswericon'/>
                                {/* <svg className='noanswerx'> <text x="0" y="15" fill="red">X</text> </svg>  */}
                                <div className='noanswerx'>\</div>
                            </div>
                        :
                            <div className='noanswerbutton-disabled'>
                                <IoCallOutline className='noanswericon'/>
                                {/* <svg className='noanswerx'width="15" height="15"> <text className ='noanswerx-text' text x="0" y="15" fill="red">X</text> </svg>  */}
                                <div className='noanswerx'>\</div>
                            </div>
                        }
                        
                        <div className='under-label' style={buttonStyle}>No Answer</div>
                    </div>
                    <div className = 'hungup'>
                        {bButtonsEnabled?
                            <div className='hungupbutton' onClick={this.onHungUp}>
                                <IoCallOutline className='hungupicon'/>                                                
                                <div className='hungupx'>X</div>
                            </div>
                        :
                            <div className='hungupbutton-disabled'>
                                <IoCallOutline className='hungupicon'/>                                            
                                <div className='hungupx'>X</div>
                            </div>
                        }
                        
                        <div className='under-label' style={buttonStyle}>Hung Up</div>
                    </div>
                    <div className = 'callissue'>
                        {bButtonsEnabled?
                            <div className='callissuebutton' onClick={this.onCallIssue}>
                                <BiErrorAlt className='callissueicon'/>
                            </div>
                        :
                            <div className='callissuebutton-disabled'>
                                <BiErrorAlt className='callissueicon'/>
                            </div>
                        }
                        
                        <div className='under-label' style={buttonStyle}>Call Issue</div>
                    </div>
                </div>
            </div>


        )
    }//renderPhoneSection

    /**
     * Helper method to render the cancel and submit buttons on the bottom of the notecard
     */
    renderFooter(){
        //Evaluate if the close button should be active
        let bCloseActive = true;
        //Check each of the sections for values:
        if(!this.state.selectedNumber){bCloseActive = false;}
        if(!this.state.contactName){bCloseActive = false;}
        if(this.state.noAnswer){bCloseActive = false;}
        if(this.state.callStarted && !this.state.callEnded){ bCloseActive=false;}

        // console.log("Close eval: ",!this.state.selectedNumber, !this.state.contactName, this.state.noAnswer, this.state.callStarted, this.state.callEnded, this.state.notes, bCloseActive);
        
        if(this.state.notes){
            // console.log("Notes length: ",this.state.notes,this.state.notes.length)
            if(this.state.notes.length === 0){bCloseActive = false;}
        }
        else{
            bCloseActive= false;
        }
        //Match the tracked states against the number of assets:
        try {
            if(this.props.tags && (this.props.tags.includes("Offline") || this.props.tags.includes("System Warning"))){
                let assetCount = (this.props.assetid.split(',')).length;
                let recordedStates = Object.keys(this.state.assetStates).length;
                // console.log("Tags: ",assetCount, recordedStates);
                if(assetCount !== recordedStates){bCloseActive=false;}                
            }    
        } catch (error) {
            console.log("Error?: ",error);            
        }

        return (
            <div className="pc-footer">
                {(this.state.startCallTime)?
                    <button className="btn btn-secondary" disabled 
                        onClick={this.confirmClose}>Cancel</button>
                :
                    <button className="btn btn-danger" 
                        onClick={this.confirmClose}>Cancel</button>
                }              
                
                {bCloseActive?
                        <button className="btn btn-primary" 
                            onClick={()=>this.handleSubmit(null)}
                        >Close</button>
                    :
                        <button className="btn btn-secondary" disabled
                            onClick={()=>this.handleSubmit(null)}
                        >Close</button>
                }
            </div>
        )
    }//renderFooter
    
    /*
    * @brief Render the content of the card:
    */
    render() {
           
        let siteTitle = null;
        let siteName = 'edge3';
        try {
            siteTitle = this.props.siteDetails[0].site;
            siteName = this.props.siteDetails[0].site.toLowerCase();
        } catch (error) {
            
        }

        const style = {};
        if (this.props.scale && this.props.scale !== 1) {
            style.transform = 'scale(' + this.props.scale + ')';
        }

        

        //Evaluate if the close button should be active
        let bCloseActive = true;
        //Check each of the sections for values:
        if(!this.state.selectedNumber){bCloseActive = false;}
        if(!this.state.contactName){bCloseActive = false;}
        if(this.state.noAnswer){bCloseActive = false;}
        if(this.state.callStarted && !this.state.callEnded){ bCloseActive=false;}

        
        if(this.state.notes){
            // console.log("Notes length: ",this.state.notes,this.state.notes.length)
            if(this.state.notes.length === 0){bCloseActive = false;}
        }
        else{
            bCloseActive= false;
        }
        //Match the tracked states against the number of assets:
        try {
            if(this.props.tags && (this.props.tags.includes("Offline") || this.props.tags.includes("System Warning"))){
                let assetCount = (this.props.assetid.split(',')).length;
                let recordedStates = Object.keys(this.state.assetStates).length;
                if(assetCount !== recordedStates){bCloseActive=false;}                
            }    
        } catch (error) {
            console.log("Error?: ",error);            
        }

        
         

        // console.log("SiteName:" ,siteName);
        return (
            <div className="phone-call" style={style}>
                
                <ReactModal isOpen={this.state.shown} className="modal-dialog phonecall-modal"
                            shouldCloseOnOverlayClick={false}
                            // onRequestClose={confirmClose}
                >
                    <div className="phonecall-modal-content" style={style} >
                        
                        <div className = 'phone-title'>
                            <img className="logo-client" src={logos[siteName]} alt={" "} />
                            <div className = 'titletext'>
                                {" Call-in"}
                            </div>    
                            <img className="logo-e3" src={logos['edge3']} alt={" "} />
                        </div>
                        
                        {/* Phone top section */}
                        <div className='phone-top'>
                            {this.renderPhoneSection()}

                            <div className = 'details'>
                                <div className="viewTabs">
                                    <Tabs className='viewSelection'
                                        defaultActiveKey={this.state.activeView} unmountOnExit={true} mountOnEnter={true} 
                                        id='uncontrolled-tab-example' 
                                            activeKey={this.state.activeView} 
                                            onSelect={(k)=> this.setState({activeView:k})}
                                    >
                                        {this.state.tabViews.map((type) => {
                                            return(  <Tab key={type} eventKey={type} title={type}/> )
                                        })} 
                                    </Tabs>
                                </div>

                                {this.state.activeView ==='status'?
                                    this.renderTable()
                                    :
                                    this.renderContacts()
                                }
                            </div>
                        {/* End phone top section */}
                        </div>
                       
                        {this.renderFooter()}
                        
                              
                    </div>
                </ReactModal>
            </div>
        );
    }
}
class SubjectDisplay extends PureComponent {
    render() {

        const onRemoveClick = () => {
            this.props.onRemoveInfraction(this.props.type);
        } //end onRemoveClick

        return (
        // <div className="table-filter alert alert-info">
        <div className="subject-display">
            <div className="infractiontext">{this.props.type}</div>
            <button className="remove" onClick={onRemoveClick}>X</button>
        </div>
        );
    }
}
