
import { isPermissionSet, getCookie } from './Util-access';
import ReconnectingWebSocket from 'reconnecting-websocket';
const URL = 'wss://i94a7bdc5c.execute-api.us-west-2.amazonaws.com/dev'
// const URL = 'wss://jd7gyzr2qk.execute-api.us-west-2.amazonaws.com/release'


/**
 *  Utilitiy class to auto optimize a query by splitting the date parameters 
 *  Old data that is not expected to change can be cached and not requeried in future refreshes
 */
class WebsocketInterfaceClass{
    /**
     * Initialize the member variables that are tracked by the class
     */
    constructor(){
        this.registeredListeners=[]; //Set instances that will be notified of a new message on the websocket
        this.username = 'unset';
        this.group = 'unset';
        this.websocket = null;
    }

    /**
     * Create a new websocket connection this occurs after the user has logged in so that the 
     * username and group are available to record in the dms_alertchannel SQL table
     * @param {*} _username : log in name of the user
     * @param {*} _groupconfig : client group assigned to the user
     */
    init(_username,_groupconfig){
        // console.log("Called init: ",_username,_groupconfig)
        this.username = _username;
        this.group = _groupconfig.group;

        let allowedTypes = 'videocut';

        // the permissions for the user, if permissions aren't set to live = 'write' then don't allow for notifications to be recieved
        if(isPermissionSet(_groupconfig,"live",'write')){allowedTypes+=",broadcast";} //backwards compatible
        if(isPermissionSet(_groupconfig,"live",{socket:true})){allowedTypes+=",broadcast";} //additional detailed permission set     

        this.websocket = new ReconnectingWebSocket(URL); //Websocket to get notifications
        //Listen to for when the open succeeds
        this.websocket.addEventListener('open',(data) => {  
            // console.log("WS connected: " , data) 
        });
        this.websocket.onopen = () => {
            // console.log("Websocket opened for : ",this.username);
            // on connection, return the user credentials to be stored in the SQL table (dms_alertchannel)
            this.websocket.send(JSON.stringify({type:'credentials',user:this.username, group: this.group,types:allowedTypes}));
            //Start a keep alive timeout:
            try {
                this.socketTimeoutID=  setInterval(() => 
                            {
                                try {  
                                    this.websocket.send(JSON.stringify({
                                        type:'keep-alive'
                                        ,sessionnumber: window.sessionStorage.getItem('SessionNumber')
                                        ,username: this.username
                                        ,token:getCookie('registered-token')})); 
                                    // console.log("Resync websocket");
                                } catch (error) {
                                    console.log("Error:" ,error,this.websocket)
                                }//send a message to the websocket server to maintain the connection
                            }//end of block to execute when interval expires 
                            ,5*60*1000);//end of setInterval
                            // ,1*30*1000);//end of setInterval (testing)
            } catch (error) {}

        }
        //Handle the socket closing
        this.websocket.onclose = () => {   console.log("on close called?", new Date())}

        //Handle receiving messages:
        this.websocket.onmessage = evt => {
                        
            const receivedMessage = JSON.parse(evt.data)
            let messageType = receivedMessage.event;

            //Check if the message has a username
            try {
                if(receivedMessage.message){
                    //Is there a username attached to the message? If so then check if it matches the current user
                    if(receivedMessage.message.username && receivedMessage.message.username !==_username){return;} //no match -> no messasge
                }
            } catch (error) {}

            //Iterate through the listeners to see who want this event type:
            for(const listener_ of this.registeredListeners){
                // console.log(" Message to send: ",messageType, listener_);
                if(listener_.type.includes(messageType)){ //is this in the desired set, then forward to the listener
                    listener_.onMessage(receivedMessage);
                }
            }
        }
    }// end init
    
    /**
     * Register a component/class with the websocket, this will enable any message of the desired type
     * to be forwarded to the registered class
     * @param {*} _data : object holding details of the registration:
     *  -name: unique name for the connection (example: LiveTab)
     *  -onMessage: callback function to recieve the message
     *  -type: types of messages to listen for (comma seperated list of multiple types)
     * @returns 
     */
    register(_data){
        // console.log("Try Register listener:" ,_data);
        //Check the min details are met
        if(!_data.name){return;}
        if(!_data.onMessage){return;}
        if(!_data.type){return;}
        //If met then add to the list
        this.registeredListeners.push(_data);
    }//end register()

    /**
     * remove a component from the registerd listeners
     * @param {*} _data : the name of the class/component to remove
     */
    release(_data){
        //Iterate over the registered users and remove the requested user:
        const index = (this.registeredListeners||[]).findIndex(elem_ => elem_.name === _data.name);
        if (index > -1) { // only splice array when item is found
            this.registeredListeners.splice(index, 1); // 2nd parameter means remove one item only
        }
    }//end release()
    /**
     * Close out the websocket interface (disconnect from the server)
     */
    close(){
        // console.log("Closed Websocket called");
        if(this.socketTimeoutID){clearInterval(this.socketTimeoutID);}
        if(this.websocket){
            this.websocket.removeEventListener('open');
            this.websocket.close(); //close the connection to the websocket API
        }
    }//end close

}//end WebsocketInterfaceClass

export const WebsocketInterface = new WebsocketInterfaceClass(); //return a singleton instance to the callers



